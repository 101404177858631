import { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';

import Table from 'components/Table';
import Pagination from 'components/Pagination';
import useSearchableTable from 'hooks/useSearchableTable';

import SearchIcon from 'components/SearchIcon';
import Placeholder from 'components/Placeholder';
import FoodsFilters, {
  IFoodFiltersValues,
} from 'components/foods/FoodsFilters';

import { IFood, TAny } from 'shared/src/types';
import {
  customWhereGenerator,
  GET_ATLAS_FOODS_COUNT_QUERY,
} from 'shared/src/graphql/food';

import { FOODS_THEAD } from 'constants/tableHeaders';

type TQuery = {
  foods: { edges: { node: IFood }[] };
};

const Foods = () => {
  const [filters, setFilters] = useState({});
  const [skipFirst, setSkipFirst] = useState(true);

  const {
    data,
    pageInfo,
    onSearch,
    isLoading,
    pagination,
    onChangePagination,
    onChangeItemsPerPage,
    onChangeWhereOptions,
  } = useSearchableTable<IFood, TQuery>({
    skipFirst,
    customWhereGenerator,
    syncInternalCache: true,
    queryNode: GET_ATLAS_FOODS_COUNT_QUERY,
    orderBy: ['isAtkinsAcceptable_DESC', 'name_ASC'],
  });

  const handleChangeFilters = (newValues: IFoodFiltersValues) => {
    setFilters({ ...filters, ...newValues });
    onChangeWhereOptions({ ...filters, ...newValues });
  };

  const handleOnSearch = (event: React.FormEvent<HTMLFormElement>) => {
    setSkipFirst(false);
    const formData = new FormData((event as TAny).target);
    const { q } = Object.fromEntries(formData.entries());
    if (isEmpty(q)) {
      return;
    }
    onSearch(event);
  };

  return (
    <div className="row">
      <FoodsFilters values={filters} onChange={handleChangeFilters} />
      <div className="col-lg-9">
        <div className="card gutter-b card-custom px-3">
          <div className="card-header border-0 pt-7 pb-0">
            <form onSubmit={handleOnSearch}>
              <div className="input-group">
                <input
                  name="q"
                  type="text"
                  placeholder="Search by name, upc etc."
                  className="form-control w-200px"
                />
                <div className="input-group-append">
                  <button
                    type="submit"
                    className="btn btn-primary px-0"
                    disabled={isLoading}>
                    <SearchIcon loading={isLoading} />
                  </button>
                </div>
              </div>
            </form>
            <div className="card-toolbar my-0">
              <Link to="/foods/add" className="btn btn-primary">
                Add Food
              </Link>
            </div>
          </div>
          <div className="card-body d-flex flex-column pt-6">
            <Table data={data} headers={FOODS_THEAD} showIn="foods" />

            <Placeholder
              spinner
              title="Loading"
              visible={!data.length && isLoading}
            />

            <Placeholder
              iconPath="Food/Chicken"
              title="Search for foods by name."
              visible={skipFirst && !data.length && !isLoading}
            />

            <Placeholder
              iconPath="Food/Chicken"
              title="No matching foods found"
              visible={!skipFirst && !data.length && !isLoading}
            />

            <div className="mt-auto">
              <hr className="mt-0 mb-8" />

              <Pagination
                pageCount={pagination.pageCount}
                count={pageInfo.count}
                currentPage={pagination.currentPage}
                onChangePagination={onChangePagination}
                onChangeItemsPerPage={onChangeItemsPerPage}
                disabled={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Foods;
