import { ReactNode } from 'react';
import { AutoFields } from 'uniforms-bootstrap4';

import DeleteConfirmation from 'components/DeleteConfirmation';

import Submit from 'forms/fields/Submit';

const InlineField = ({ children }: { children?: ReactNode }) => {
  return (
    <div className="inline-field list-group list-group-custom">{children}</div>
  );
};

interface IAsideMenuProps {
  submit?: boolean;
  recipeId?: string;
  isLoading?: boolean;
  onDelete?: () => void;
}

const AsideMenu = ({
  submit,
  onDelete,
  recipeId,
  isLoading,
}: IAsideMenuProps) => {
  return (
    <div className="col">
      <div className="aside-menu">
        <AutoFields fields={['servings', 'servingTable']} />
        <AutoFields fields={['netCarbsOverride']} element={InlineField} />
      </div>
      <div className="aside-menu mt-4">
        <AutoFields fields={['status', 'publishingStatus']} />

        {submit && <Submit working={isLoading} />}
        {onDelete && recipeId && (
          <DeleteConfirmation
            className="mt-2"
            id={recipeId}
            onComplete={onDelete}
          />
        )}
      </div>
    </div>
  );
};

export default AsideMenu;
