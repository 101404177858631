import { DocumentNode } from 'graphql';
import { useState, useEffect } from 'react';

import useApolloCrud from 'shared/src/hooks/useApolloCrud';
import usePagination from 'shared/src/hooks/useApolloPagination';

import { IObject, TAny } from 'shared/src/types';

import ITEMS_PER_PAGE_OPTIONS from 'constants/itemsPerPageOptions';

const ITEMS_PER_PAGE = ITEMS_PER_PAGE_OPTIONS[0];

type ICustomWhereGeneratorReturn = {
  where: IObject;
};

const defaultWhereGenerator = ({ q }: IObject) => {
  const toRet: IObject = {};

  if (q) {
    toRet.name = { text: { search: { term: q } } };
  }

  return { where: toRet };
};
export interface useSearchableTableProps {
  orderBy?: string | string[];
  skipFirst?: boolean;
  initWhere?: IObject;
  syncInternalCache?: boolean;
  queryNode: DocumentNode;
  customWhereGenerator?: (vars: IObject) => ICustomWhereGeneratorReturn;
}

const useSearchableTable = <IData, TQuery>({
  queryNode,
  initWhere = {},
  syncInternalCache,
  skipFirst = false,
  orderBy = 'title_ASC',
  customWhereGenerator = defaultWhereGenerator,
}: useSearchableTableProps) => {
  const [whereOptions, setWhereOptions] = useState({ ...initWhere });

  const {
    skip,
    first,
    setPage,
    setCount,
    setFirst,
    pageCount,
    currentPage,
    setLastPage,
  } = usePagination({ itemsPerPage: ITEMS_PER_PAGE });

  const {
    query: { data, pageInfo, refetch, loading },
  } = useApolloCrud<TQuery, IData>({
    queryNode,
    syncInternalCache,
    queryOptions: {
      fetchPolicy: 'network-only',
      variables: {
        skip,
        first,
        order: orderBy,
        ...customWhereGenerator(whereOptions),
      },
      skip: skipFirst,
    },
  });

  useEffect(() => {
    if (pageInfo.count) {
      setCount(pageInfo.count);
    }
  }, [pageInfo.count]);

  const handleChangePagination = (text: string) => {
    if (!text) {
      return;
    }

    switch (text) {
      case '«First':
        setPage(1);
        break;
      case '»Last':
        setLastPage();
        break;
      case '›Next':
        setPage(currentPage + 1);
        break;
      case '‹Previous':
        setPage(currentPage - 1);
        break;
      default:
        setPage(+text);
    }
  };

  const handleChangeItemsPerPage = (value: number) => {
    setFirst(value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData((event as TAny).target);
    const { q } = Object.fromEntries(formData.entries());

    setWhereOptions({ ...whereOptions, q });
    setPage(1);
  };

  const handleChangeWhereOptions = (newOptions: IObject) => {
    setWhereOptions({ ...whereOptions, ...newOptions });
    setPage(1);
  };

  return {
    refetch,
    pageInfo,
    data: data || [],
    isLoading: loading,
    pagination: {
      pageCount,
      currentPage,
    },
    onSearch: handleSubmit,
    onChangePagination: handleChangePagination,
    onChangeItemsPerPage: handleChangeItemsPerPage,
    onChangeWhereOptions: handleChangeWhereOptions,
  };
};

export default useSearchableTable;
