import { useState } from 'react';

import get from 'lodash/get';
import SVG from 'react-inlinesvg';
import format from 'date-fns/format';
import isEmpty from 'lodash/isEmpty';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useMutation } from '@apollo/client';
import formatDistance from 'date-fns/formatDistance';

import { IUser } from 'shared/src/types';
import useAuth from 'shared/src/hooks/useAuth';

import USER_DETAIL_FIELDS from 'constants/userDetailFields';
import { FORCE_PASSWORD_MUTATION } from 'graphql/users';

interface UsersDetailProps {
  data: IUser;
  show: boolean;
  handleClose: () => void;
}

const UsersDetail = ({ data, show, handleClose }: UsersDetailProps) => {
  const [input, setInput] = useState('');
  const { resetPassword } = useAuth();
  const [sendForcePassword, { loading }] = useMutation(FORCE_PASSWORD_MUTATION);

  const handleResetPassword = async () => {
    try {
      await sendForcePassword({
        variables: {
          username: data.username,
          password: input,
        },
      });
      setInput('');
      toast.success('Password reset successfully');
    } catch (error) {
      const { message } = error as Error;
      toast.error(message);
    }
  };

  const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };

  const handleSendPasswordReset = async () => {
    try {
      await resetPassword(data.email);
      toast.success('Email sent successfully');
    } catch (error) {
      const { message } = error as Error;
      toast.error(message);
    }
  };
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>User Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="list-group list-group-flush">
            {USER_DETAIL_FIELDS.map(
              ({ name, value, dateFormat, distance, mailto }) => {
                let userValue: string | undefined | JSX.Element;
                if (Array.isArray(value)) {
                  userValue = '';
                  value.forEach((v) => {
                    const uv = get(data, v, null);
                    if (uv) {
                      userValue += `${uv}, `;
                    }
                  });

                  userValue = userValue.slice(0, -2);
                } else {
                  userValue = get(data, value, undefined) as string | undefined;
                  if (userValue) {
                    userValue = userValue.trim();
                  }
                  if (
                    userValue &&
                    dateFormat &&
                    typeof userValue === 'string'
                  ) {
                    userValue = format(new Date(userValue), dateFormat);
                  }

                  if (userValue && distance && typeof userValue === 'string') {
                    userValue = formatDistance(
                      new Date(userValue),
                      new Date(),
                      {
                        addSuffix: true,
                      }
                    );
                  }

                  if (userValue && mailto) {
                    userValue = (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`mailto:${userValue}`}>
                        {userValue}
                      </a>
                    );
                  }
                }

                if (isEmpty(userValue)) {
                  return null;
                }
                return (
                  <div
                    key={`${data.id}-${name}`}
                    className="list-group-item py-2">
                    <div className="row">
                      <div className="col-4">{name}:</div>
                      <div className="col-8 font-weight-bold">{userValue}</div>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <p className="text-primary font-weight-bolder w-100 text-center">
            Force reset password
          </p>
          <Form.Group className="d-block w-100" controlId="formBasicEmail">
            <Form.Control
              value={input}
              type="password"
              placeholder="New password"
              onChange={handleChangeText}
            />
          </Form.Group>

          <Button
            variant="secondary"
            className="btn-block"
            disabled={loading}
            onClick={handleResetPassword}>
            <span className="text-primary font-weight-bolder">
              {loading ? 'Sending' : 'Reset Password'}
            </span>
          </Button>

          <hr className="d-block w-100 my-6" />

          <Button
            variant="secondary"
            className="btn-block"
            onClick={handleSendPasswordReset}>
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                className="text-primary"
                src="/svg/icons/Communication/Mail-opened.svg"></SVG>
            </span>
            <span className="text-primary font-weight-bolder">
              Send Password Reset
            </span>
          </Button>
          <Button variant="secondary" className="btn-block" disabled>
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                className="text-primary"
                src="/svg/icons/General/User.svg"></SVG>
            </span>
            <span className="text-primary font-weight-bolder">
              Login as {data.fullName}
            </span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UsersDetail;
