import isEmpty from 'lodash/isEmpty';
import useApiConstants from 'shared/src/hooks/useApiConstants';

import RECIPES_RANGE_FILTER from 'constants/recipesRangeFilter';
import { IObject } from 'shared/src/types';

export interface IRecipeFiltersValues {
  phases?: string[];
  difficulties?: string[];
  publishingStatuses?: string[];

  caloriesMin?: number;
  caloriesMax?: number;
  netCarbsMin?: number;
  netCarbsMax?: number;
  proteinMin?: number;
  proteinMax?: number;
  sugarMin?: number;
  sugarMax?: number;
  fiberMin?: number;
  fiberMax?: number;
  prepTimeMin?: number;
  prepTimeMax?: number;
  cookTimeMin?: number;
  cookTimeMax?: number;
}

interface IRecipesFiltersProps {
  values: IRecipeFiltersValues;
  onChange: (newValues: IRecipeFiltersValues) => void;
}

const RecipesFilters = ({ values, onChange }: IRecipesFiltersProps) => {
  const { phases, difficulties, publishingStatuses } = values;
  const {
    phases: phasesConstants,
    difficulties: difficultiesConstants,
    publishingStatuses: publishingStatusesCosntants,
  } = useApiConstants();

  const handleClickItem = (newValues: IRecipeFiltersValues) => () => {
    onChange(newValues);
  };

  const handleChangeFormValues = (event: React.FormEvent<HTMLFormElement>) => {
    const target = event.target as HTMLInputElement;
    const newValue: IObject = {};
    newValue[target.name] = isEmpty(target.value) ? null : +target.value;
    onChange(newValue);
  };

  return (
    <div className="col">
      <div className="aside-menu">
        <ul className="menu-nav">
          <li className="menu-section mt-0">
            <div className="menu-text">Filters</div>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
          <li
            className={`menu-item ${
              !phases || !phases.length ? 'menu-item-active' : ''
            }`}
            aria-haspopup="true">
            <a className="menu-link" onClick={handleClickItem({ phases: [] })}>
              <span className="menu-text">All Phases</span>
            </a>
          </li>

          {phasesConstants.map(({ objectId, name }) => (
            <li
              key={`recipe-phase-${objectId}`}
              className={`menu-item ${
                phases && phases.length === 1 && phases[0] === objectId
                  ? 'menu-item-active'
                  : ''
              }`}
              aria-haspopup="true">
              <a
                className="menu-link"
                onClick={handleClickItem({ phases: [objectId] })}>
                <span className="menu-text">{name}</span>
              </a>
            </li>
          ))}

          <hr className="m-4" />

          <li
            className={`menu-item menu-info ${
              !difficulties || !difficulties.length ? 'menu-item-active' : ''
            }`}
            aria-haspopup="true">
            <a
              className="menu-link"
              onClick={handleClickItem({ difficulties: [] })}>
              <span className="menu-text">Any Dificulties</span>
            </a>
          </li>

          {difficultiesConstants.map(({ objectId, name }) => (
            <li
              key={`recipe-difficulty-${objectId}`}
              className={`menu-item menu-info ${
                difficulties &&
                difficulties.length === 1 &&
                difficulties[0] === objectId
                  ? 'menu-item-active'
                  : ''
              }`}
              aria-haspopup="true">
              <a
                className="menu-link"
                onClick={handleClickItem({ difficulties: [objectId] })}>
                <span className="menu-text">{name}</span>
              </a>
            </li>
          ))}

          <hr className="m-4" />

          <li
            className={`menu-item menu-success ${
              !publishingStatuses || !publishingStatuses.length
                ? 'menu-item-active'
                : ''
            }`}
            aria-haspopup="true">
            <a
              className="menu-link"
              onClick={handleClickItem({
                publishingStatuses: [],
              })}>
              <span className="menu-text">Any Statuses</span>
            </a>
          </li>

          {publishingStatusesCosntants.map(({ objectId, name }) => (
            <li
              key={`recipe-publishing-status-${objectId}`}
              className={`menu-item menu-success ${
                publishingStatuses &&
                publishingStatuses.length === 1 &&
                publishingStatuses[0] === objectId
                  ? 'menu-item-active'
                  : ''
              }`}
              aria-haspopup="true">
              <a
                className="menu-link"
                onClick={handleClickItem({ publishingStatuses: [objectId] })}>
                <span className="menu-text">{name}</span>
              </a>
            </li>
          ))}

          <hr className="m-4" />
        </ul>
        <form onChange={handleChangeFormValues}>
          {RECIPES_RANGE_FILTER.map(({ title, subtitle, value }) => (
            <div key={`recipe-range-${value}`} className="form-row mb-2">
              <div className="col-sm-6 d-flex align-items-center">
                <span className="font-weight-bolder font-size-sm">
                  {`${title} `}
                  <span className="text-muted small">• {subtitle}</span>
                </span>
              </div>
              <div className="col px-0">
                <input
                  name={`${value}Min`}
                  placeholder="Min"
                  type="number"
                  className="form-control form-control-sm h-25px w-45px px-1 ml-auto text-center font-size-xxs"
                />
              </div>
              <div className="col pr-0">
                <input
                  name={`${value}Max`}
                  placeholder="Max"
                  type="number"
                  className="form-control form-control-sm h-25px w-45px px-1 text-center font-size-xxs"
                />
              </div>
            </div>
          ))}
        </form>
      </div>
    </div>
  );
};

export default RecipesFilters;
