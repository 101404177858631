import useApiConstants from 'shared/src/hooks/useApiConstants';

export interface IFoodFiltersValues {
  phases?: string[];
  foodCategories?: string[];
}

interface IFoodsFiltersProps {
  values: IFoodFiltersValues;
  onChange: (newValues: IFoodFiltersValues) => void;
}

const FoodsFilters = ({ values, onChange }: IFoodsFiltersProps) => {
  const { phases, foodCategories } = values;
  const {
    phases: phasesConstants,
    foodCategories: foodCategoriesConstants,
  } = useApiConstants();

  const handleClickItem = (newValues: IFoodFiltersValues) => () => {
    onChange(newValues);
  };

  return (
    <div className="col">
      <div className="aside-menu">
        <ul className="menu-nav">
          <li className="menu-section mt-0">
            <div className="menu-text">Filters</div>
          </li>
          <li
            className={`menu-item ${
              !phases || !phases.length ? 'menu-item-active' : ''
            }`}
            aria-haspopup="true">
            <a className="menu-link" onClick={handleClickItem({ phases: [] })}>
              <span className="menu-text">All Phases</span>
            </a>
          </li>

          {phasesConstants.map(({ objectId, name }) => (
            <li
              key={`phase-${objectId}`}
              className={`menu-item ${
                phases && phases[0] === objectId ? 'menu-item-active' : ''
              }`}
              aria-haspopup="true">
              <a
                className="menu-link"
                onClick={handleClickItem({ phases: [objectId] })}>
                <span className="menu-text">{name}</span>
              </a>
            </li>
          ))}

          <hr className="m-4" />
          <li
            className={`menu-item menu-info ${
              !foodCategories || !foodCategories.length
                ? 'menu-item-active'
                : ''
            }`}
            aria-haspopup="true">
            <a
              className="menu-link"
              onClick={handleClickItem({
                foodCategories: [],
              })}>
              <span className="menu-text">All Categories</span>
            </a>
          </li>

          {foodCategoriesConstants.map(({ objectId, name }) => (
            <li
              key={`food-category-${objectId}`}
              className={`menu-item menu-info ${
                foodCategories && foodCategories[0] === objectId
                  ? 'menu-item-active'
                  : ''
              }`}
              aria-haspopup="true">
              <a
                className="menu-link"
                onClick={handleClickItem({ foodCategories: [objectId] })}>
                <span className="menu-text">{name}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FoodsFilters;
