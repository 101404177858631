import { useState, useEffect } from 'react';
import Select, { OptionsType } from 'react-select';
import { connectField, HTMLFieldProps } from 'uniforms';

import useApiConstants from 'shared/src/hooks/useApiConstants';

type TOptionAPIType = { id: string; name: string };
type TOptionType = { label: string; value: string };

const mapToOption = (data: TOptionAPIType[] = []) => {
  return data.map(({ id, name }) => ({ label: name, value: id }));
};

const mapToOptionApi = (data: TOptionType[] = []) => {
  return data.map(({ label, value }) => ({
    id: value,
    name: label,
  })) as TOptionAPIType[];
};

const MultiSelect = ({
  name,
  value,
  error,
  label,
  disabled,
  required,
  onChange,
  placeholder,
}: HTMLFieldProps<TOptionAPIType[], HTMLSelectElement>) => {
  const [selectValues, setSelectValues] = useState<TOptionAPIType[]>([]);
  const constants = useApiConstants();
  let data = constants.recipeCourses;
  switch (name) {
    case 'cookingMethods':
      data = constants.recipeCookingMethods;
      break;
    case 'styles':
      data = constants.recipeStyles;
      break;
    case 'dishTypes':
      data = constants.recipeDishTypes;
      break;
    case 'keyIngredients':
      data = constants.recipeKeyIngredients;
  }
  const options = mapToOption(data);
  const values = mapToOption(selectValues);

  useEffect(() => {
    if (Array.isArray(value)) {
      setSelectValues(value);
    }
  }, [value]);

  const handleOnChangeSelect = (values: OptionsType<TOptionType>) => {
    const mappedValues = mapToOptionApi(values as TOptionType[]);
    onChange(mappedValues);
  };

  return (
    <div
      className={`form-group ${required ? 'required' : ''} ${
        error ? 'is-invalid' : ''
      }`}>
      {label && (
        <label className={`${error ? 'text-danger' : ''}`}>{label}</label>
      )}
      <Select
        isMulti
        error={error}
        value={values}
        options={options}
        isDisabled={disabled}
        className="multi-select"
        classNamePrefix="select"
        placeholder={placeholder}
        onChange={handleOnChangeSelect}
      />
      {error && <span className="form-text text-danger">{error.message}</span>}
    </div>
  );
};

export default connectField(MultiSelect);
