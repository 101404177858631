import { useMemo, useRef, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import { DeepPartial } from 'uniforms';
import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';
import { useMutation } from '@apollo/client';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import { AutoForm, AutoField, AutoFields } from 'uniforms-bootstrap4';

import { SRecipesCU } from 'forms/schemas';
import LoadingIndicator from 'components/LoadingIndicator';

import useForm from 'shared/src/hooks/useForm';
import useApolloCrud from 'shared/src/hooks/useApolloCrud';
import { convertToGraphqlModel } from 'shared/src/utils/GraphqlHelper';

import { IRecipe, TAny } from 'shared/src/types';
import { slugify } from 'shared/src/utils/String';

import {
  GET_RECIPE_QUERY,
  CREATE_RECIPE_MUTATION,
  UPDATE_RECIPE_MUTATION,
} from 'shared/src/graphql/recipe';
import { IRecipeForm } from 'types';
import { DELETE_RECIPE } from 'graphql/recipe';

import AsideMenu from 'components/recipes/AsideMenu';
import pick from 'lodash/pick';
import { ELEMENTS } from 'hooks/useServing';

type TQuery = {
  recipes: { edges: { node: IRecipe }[] };
};

const RecipesCU = () => {
  const { id } = useParams<{ id: string }>();
  const isEdit = !!id;

  const form = useRef<TAny>(null);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const [deleteRecipe] = useMutation(DELETE_RECIPE);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const {
    query: { data, loading, refetch },
    mutations: {
      create: { call: create },
      update: { call: update },
    },
  } = useApolloCrud<TQuery, IRecipe>({
    queryNode: GET_RECIPE_QUERY,
    queryOptions: {
      fetchPolicy: 'network-only',
      variables: {
        id,
        recipes: true,
      },
    },
    cachePointers: {
      internal: true,
    },
    skipFirstQuery: !isEdit,
    createMutationNode: CREATE_RECIPE_MUTATION,
    updateMutationNode: UPDATE_RECIPE_MUTATION,
  });

  const recipe: Partial<IRecipe> = useMemo(() => {
    if (isEmpty(data)) {
      return {};
    }
    return data[0];
  }, [data]);

  const { schema } = useForm(SRecipesCU, recipe);

  if (!loading && isEmpty(recipe) && isEdit) {
    return <Redirect to="/recipes" />;
  }

  if (loading) {
    return <LoadingIndicator visible={true} />;
  }

  const handleOnSubmit = async (_data: DeepPartial<IRecipeForm>) => {
    setIsLoading(true);
    const data = cloneDeep(_data);

    data.foods = (data.foods ?? []).map((food: TAny) => {
      const { conversion } = food;
      food.weights = JSON.stringify(food.weights);
      const toRet = {
        ...pick(food, [
          'order',
          'servingUnit',
          'mndId',
          'weights',
          'isGramless',
        ]),
        fat: food.fat || food.totalFat || 0,
        foodName:
          food.name ?? food.foodName ?? food.food.name ?? food.food.foodName,
        servingSize: +food.servingSize ?? 1,
        ...(food?.food?.id && {
          food: {
            id: food.food.id,
          },
        }),
      };

      ELEMENTS.forEach((key) => {
        const name = key === 'totalFat' ? 'fat' : key;
        /*
        toRet[name as keyof typeof toRet] =
          ((conversion[name as keyof typeof conversion] ?? 0) *
            +food.servingSize) /
          +conversion.servingSize;*/
        toRet[name as keyof typeof toRet] =
          conversion[name as keyof typeof conversion] ?? 0;
      });

      return toRet;
    });

    data.recipes = ((data.recipes as TAny) ?? []).map(
      ({ food, order, servingSize }: TAny) => ({
        order,
        quantity: servingSize,
        recipeId: food.objectId,
        includedRecipe: {
          ...food,
          fat: food.totalFat || food.fat || 0,
        },
      })
    );

    try {
      let cdata = await convertToGraphqlModel(data, recipe, [
        'foods',
        'recipes',
      ]);

      if ((!cdata.image && !data.image) || cdata?.image?.remove) {
        cdata.image = {
          file: null,
        };
      }

      if (!isEmpty(cdata.servingTable)) {
        const servingTable = { ...cdata.servingTable };
        cdata = { ...cdata, ...servingTable };
      }

      delete cdata.servingTable;

      cdata.totalFat = cdata.totalFat || cdata.fat || 0;
      delete cdata.fat;

      if (isEdit) {
        await update({ variables: { fields: cdata, id } });
      } else {
        const {
          data: {
            createRecipe: {
              recipe: { objectId },
            },
          },
        } = await create({ variables: { fields: cdata } });

        window.location.href = `/recipes/${objectId}/edit`;
        return;
      }

      await refetch();

      toast.success(`Recipe ${isEdit ? 'updated' : 'saved'} successfuly`);
    } catch {
      toast.error('Something went wrong!');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setIsLoadingDelete(true);
      await deleteRecipe({
        variables: {
          id: recipe.objectId,
        },
      });

      setIsLoadingDelete(false);
      history.push('/recipes');
    } catch (error) {
      const { message } = error as Error;
      toast.error(message);
    }
  };

  return (
    <>
      <LoadingIndicator visible={isLoadingDelete} />
      <AutoForm
        ref={form}
        placeholder
        showInlineError
        schema={schema}
        onSubmit={handleOnSubmit}
        onChange={(key, value) => {
          const model = form?.current?.state?.model;
          if (
            !recipe?.objectId &&
            key === 'name' &&
            (isEmpty(model?.recipeUrl) ||
              slugify(model?.name) === model?.recipeUrl)
          ) {
            form.current.onChange('recipeUrl', slugify(value));
          }
        }}>
        <div className="row">
          <div className="col-lg-9">
            <div className="card gutter-b card-custom px-3">
              <div className="card-body">
                <AutoFields fields={['name', 'featured', 'foods', 'recipes']} />
                <div className="row mt-10 mb-6">
                  <div className="col-lg-6">
                    <AutoField name="phase" />
                  </div>
                  <div className="col-lg-6">
                    <AutoField name="difficulty" />
                  </div>
                  <div className="col-lg-6">
                    <AutoField name="preparationTime" />
                  </div>
                  <div className="col-lg-6">
                    <AutoField name="cookingTime" />
                  </div>
                </div>
                <AutoFields
                  fields={[
                    'overview',
                    'directions',
                    'cookingTips',
                    'cookingMethods',
                    'courses',
                    'styles',
                    'dishTypes',
                    'keyIngredients',
                    'image',
                  ]}
                />
                <div className="row mt-10 mb-6">
                  <div className="col-12">
                    <span className="d-block mb-2">
                      <strong>Metadata</strong>
                    </span>
                    <AutoFields
                      fields={['metaH1', 'metaTitle', 'recipeUrl', 'metaData']}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <AsideMenu
            submit
            isLoading={isLoading}
            onDelete={handleDelete}
            recipeId={recipe.objectId}
          />
        </div>
      </AutoForm>
    </>
  );
};

export default RecipesCU;
