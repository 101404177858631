import pick from 'lodash/pick';

import { useForm, connectField, HTMLFieldProps } from 'uniforms';

import BaseField, {
  IRecipe,
  IServingTableProps as IBaseFieldProps,
} from 'components/servingTable/ServingTable';

type IServingTableProps = {
  valuesFrom: string[];
};

const Ingredients = ({
  value,
  label,
  error,
  onChange,
  valuesFrom = ['foods', 'recipes', 'servings'],
}: HTMLFieldProps<
  { id: string; name: string },
  HTMLDivElement,
  IBaseFieldProps & IServingTableProps
>) => {
  const { model } = useForm<Partial<IRecipe>>();
  const formValues = pick(model, valuesFrom);

  return (
    <>
      {label && (
        <label className={`${error ? 'text-danger' : ''}`}>{label}</label>
      )}

      <BaseField value={value} onChange={onChange} formValues={formValues} />
    </>
  );
};

export default connectField(Ingredients);
