import { useMemo, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import { DeepPartial, useForm } from 'uniforms';

import FoodServingConversionsAdd from './FoodServingConversionsAdd';
import FoodServingConversionsItem from './FoodServingConversionsItem';

import { IServingConversion } from 'shared/src/types';
import FoodServingConversionsSmart from './FoodServingConversionsSmart';
import { IFoodForm } from 'types';

const isValidSmartUnit = (unit = '') => {
  return !isEmpty(unit);
};

interface IFoodServingConversionsProps {
  data?: IServingConversion[];
  onConversionRemoved: (index: number) => void;
  onConversionAdded: (newConversion: IServingConversion) => void;
  onConversionUpdated: (
    conversionUpdated: IServingConversion,
    index: number
  ) => void;
}

const FoodServingConversions = ({
  data = [],
  onConversionAdded,
  onConversionRemoved,
  onConversionUpdated,
}: IFoodServingConversionsProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalSmartVisible, setIsModalSmartVisible] = useState(false);
  const [selectedConversionIndex, setSelectedConversionIndex] = useState(-1);

  const { model } = useForm<DeepPartial<IFoodForm>>();

  const isValidSmartConversion = useMemo(
    () => isValidSmartUnit(model.servingUnit),
    [model]
  );
  const validConversions = data.filter(({ isBase }) => !isBase);
  const selectedConversion = validConversions?.[selectedConversionIndex];

  const handleClose = (newConversion?: IServingConversion) => {
    setIsModalVisible(false);
    setSelectedConversionIndex(-1);

    if (newConversion) {
      selectedConversionIndex !== -1
        ? onConversionUpdated(newConversion, selectedConversionIndex)
        : onConversionAdded(newConversion);
    }
  };

  const handleSmartClose = (newConversion?: IServingConversion) => {
    setIsModalSmartVisible(false);
    setSelectedConversionIndex(-1);

    newConversion && onConversionAdded(newConversion);
  };

  const handleUpdateConversion = (index: number) => {
    setIsModalVisible(true);
    setSelectedConversionIndex(index);
  };

  const handleAddConversion = () => {
    setSelectedConversionIndex(-1);
    setIsModalVisible(true);
  };

  const handleAddSmart = () => {
    setIsModalSmartVisible(true);
  };

  return (
    <>
      <FoodServingConversionsAdd
        show={isModalVisible}
        onClose={handleClose}
        data={selectedConversion}
      />
      <FoodServingConversionsSmart
        model={model}
        show={isModalSmartVisible}
        onClose={handleSmartClose}
      />
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <label>Conversions</label>
          <div>
            {isValidSmartConversion && (
              <a onClick={handleAddSmart} className="pointer mr-4">
                <i className="flaticon2-start-up"></i>
              </a>
            )}
            <a onClick={handleAddConversion} className="pointer">
              <i className="flaticon2-add-1"></i>
            </a>
          </div>
        </div>
        <hr className="my-0" />

        <div className="inline-field list-group">
          {validConversions.map((item, index) => (
            <FoodServingConversionsItem
              data={item}
              index={index}
              key={`serving-conversion-${index}`}
              onRemoveConversion={onConversionRemoved}
              onUpdateConversion={handleUpdateConversion}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default FoodServingConversions;
