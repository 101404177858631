/* eslint-disable eqeqeq */
import { useState } from 'react';

import Table from 'components/Table';
import Pagination from 'components/Pagination';
import SearchIcon from 'components/SearchIcon';
import Placeholder from 'components/Placeholder';
import RecipesFilters, {
  IRecipeFiltersValues,
} from 'components/recipes/RecipesFilters';

import { IRecipe } from 'shared/src/types';
import useSearchableTable from 'hooks/useSearchableTable';

import {
  customWhereGenerator,
  GET_ATLAS_RECIPES_QUERY,
} from 'graphql/recipeLegacy';

import { RECIPES_THEAD } from 'constants/tableHeaders';

type TQuery = {
  recipes: { edges: { node: IRecipe }[] };
};

const RecipesLegacy = () => {
  const [filters, setFilters] = useState({});

  const {
    data,
    pageInfo,
    onSearch,
    isLoading,
    pagination,
    onChangePagination,
    onChangeItemsPerPage,
    onChangeWhereOptions,
  } = useSearchableTable<IRecipe, TQuery>({
    orderBy: 'name_ASC',
    customWhereGenerator,
    syncInternalCache: true,
    queryNode: GET_ATLAS_RECIPES_QUERY,
  });

  const handleChangeFilters = (newValues: IRecipeFiltersValues) => {
    const newFilters = { ...filters, ...newValues };
    setFilters(newFilters);
    onChangeWhereOptions(newFilters);
  };

  return (
    <div className="row">
      <RecipesFilters values={filters} onChange={handleChangeFilters} />
      <div className="col-lg-9">
        <div className="card gutter-b card-custom px-3">
          <div className="card-header border-0 pt-7 pb-0">
            <form onSubmit={onSearch}>
              <div className="input-group">
                <input
                  name="q"
                  type="text"
                  placeholder="Search by name, ingredients etc."
                  className="form-control w-250px"
                />
                <div className="input-group-append">
                  <button
                    type="submit"
                    className="btn btn-primary px-0"
                    disabled={isLoading}>
                    <SearchIcon loading={isLoading} />
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="card-body d-flex flex-column pt-6">
            <Table data={data} headers={RECIPES_THEAD} showIn="recipesLegacy" />

            <Placeholder
              spinner
              title="Loading"
              visible={!data.length && isLoading}
            />

            <Placeholder
              iconPath="Cooking/Cooking-pot"
              title="No matching recipes found"
              visible={!data.length && !isLoading}
            />

            <div className="mt-auto">
              <hr className="mt-0 mb-8" />

              <Pagination
                disabled={isLoading}
                count={pageInfo.count}
                pageCount={pagination.pageCount}
                currentPage={pagination.currentPage}
                onChangePagination={onChangePagination}
                onChangeItemsPerPage={onChangeItemsPerPage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipesLegacy;
