import { AutoForm } from 'uniforms';
import Modal from 'react-bootstrap/Modal';
import { AutoFields } from 'uniforms-bootstrap4';

import { SFoodsNutrients } from 'forms/schemas';

import useForm from 'shared/src/hooks/useForm';
import Submit from 'forms/fields/Submit';
import { IServingConversion } from 'shared/src/types';

interface IfoodServingConversionsAddProps {
  data: IServingConversion;
  show: boolean;
  onClose: (newConvertion?: IServingConversion) => void;
}

const FoodServingConversionsAdd = ({
  data,
  show,
  onClose,
}: IfoodServingConversionsAddProps) => {
  const { schema } = useForm(SFoodsNutrients, data ?? {});

  const handleSubmit = (data: Partial<IServingConversion>) => {
    onClose(data as IServingConversion);
  };

  return (
    <>
      <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Convertion</Modal.Title>
        </Modal.Header>
        <AutoForm
          placeholder
          showInlineError
          schema={schema}
          onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-12">
                <AutoFields />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-block">
            <Submit text="Save" textWorking="Saving" />
          </Modal.Footer>
        </AutoForm>
      </Modal>
    </>
  );
};

export default FoodServingConversionsAdd;
