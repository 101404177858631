import { DeepPartial } from 'uniforms';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { AutoForm, AutoFields } from 'uniforms-bootstrap4';

import useAuth from 'shared/src/hooks/useAuth';
import useForm from 'shared/src/hooks/useForm';

import { SForgotPassword } from 'shared/src/forms/schemas';
import Submit from 'forms/fields/Submit';

const ForgotPassword = () => {
  const {
    resetPassword,
    isLoadingResetPasswordMutation: isWorking,
  } = useAuth();
  const { schema } = useForm(SForgotPassword);

  const handleOnSubmit = ({
    email,
  }: DeepPartial<{
    email: string;
  }>) => {
    try {
      resetPassword(email as string);
      toast.success('Email sent');
    } catch (error) {
      const { message } = error as Error;
      toast.error(message);
    }
  };

  return (
    <div className="card border-0">
      <div className="card-header text-center">
        <img
          className="mb-5"
          alt="Atkins Logo"
          src="/svg/logos/atkins.svg"
          height="108"
        />
        <h2 className="card-title h1 mb-2">Forgotten Password ?</h2>
        <p className="text-muted">Enter your email to reset your password</p>
      </div>
      <div className="card-body">
        <AutoForm showInlineError schema={schema} onSubmit={handleOnSubmit}>
          <AutoFields />
          <Submit working={isWorking} text="Send email" textWorking="Sending" />
          <Link
            to="/auth/login"
            className="text-right d-block btn-link text-capitalize mt-2">
            Go Back
          </Link>
        </AutoForm>
      </div>
    </div>
  );
};

export default ForgotPassword;
