import SVG from 'react-inlinesvg';

interface IPlaceholderProps {
  title?: string;
  visible: boolean;
  spinner?: boolean;
  iconPath?: string;
}

const Placeholder = ({
  title = 'No data found',
  spinner,
  visible,
  iconPath = 'Cooking/Cooking-pot',
}: IPlaceholderProps) => {
  if (!visible) {
    return null;
  }

  const iconElm = spinner ? (
    <div className="spinner-border text-primary my-6" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  ) : (
    <span className="svg-icon svg-icon-5x svg-icon-primary">
      <SVG src={`/svg/icons/${iconPath}.svg`} />
    </span>
  );

  return (
    <div className="d-flex justify-content-center align-items-center mt-auto">
      <div className="pt-30 pt-md-25 pb-0 px-5 text-center">
        <div className="d-flex flex-center position-relative mb-25">
          <span className="svg svg-fill-primary opacity-12 position-absolute">
            <svg width="175" height="200">
              <polyline points="87,0 174,50 174,150 87,200 0,150 0,50 87,0"></polyline>
            </svg>
          </span>
          {iconElm}
        </div>
        <h4 className="font-size-h6 d-block font-weight-bold mb-7 text-dark-50">
          {title}
        </h4>
      </div>
    </div>
  );
};

export default Placeholder;
