import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { useQuery } from '@apollo/client';
import { Redirect, useParams } from 'react-router-dom';
import { AutoForm, AutoField, AutoFields } from 'uniforms-bootstrap4';

import AsideMenu from 'components/recipes/AsideMenu';
import LoadingIndicator from 'components/LoadingIndicator';

import useForm from 'shared/src/hooks/useForm';

import { IRecipe, TAny } from 'shared/src/types';
import { GET_RECIPE_QUERY } from 'graphql/recipeLegacy';

import { SRecipesCU } from 'forms/schemas';

const SRecipeLegacy = cloneDeep(SRecipesCU);

Object.keys(SRecipeLegacy.properties).forEach((key) => {
  const item: TAny =
    SRecipeLegacy.properties[key as keyof typeof SRecipeLegacy.properties];

  item.disabled = true;
  if (!item.uniforms) {
    item.uniforms = {};
  }
  item.uniforms.disabled = true;
});

type TQuery = {
  recipeLegacies: { edges: { node: IRecipe }[] };
};

const RecipeLegacyDetail = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useQuery<TQuery>(GET_RECIPE_QUERY, {
    variables: {
      id,
      recipes: true,
    },
  });

  const recipe: TAny = data?.recipeLegacies?.edges?.map(({ node }) => node)[0];

  const { schema } = useForm(SRecipeLegacy, recipe);

  if (!loading && isEmpty(recipe)) {
    return <Redirect to="/recipesLegacy" />;
  }

  if (loading) {
    return <LoadingIndicator visible={true} />;
  }
  return (
    <AutoForm placeholder showInlineError schema={schema}>
      <div className="row">
        <div className="col-lg-9">
          <div className="card gutter-b card-custom px-3">
            <div className="card-body">
              <AutoFields fields={['name', 'featured', 'foods', 'recipes']} />
              <div className="row mt-10 mb-6">
                <div className="col-lg-6">
                  <AutoField name="phase" />
                </div>
                <div className="col-lg-6">
                  <AutoField name="difficulty" />
                </div>
                <div className="col-lg-6">
                  <AutoField name="preparationTime" />
                </div>
                <div className="col-lg-6">
                  <AutoField name="cookingTime" />
                </div>
              </div>
              <AutoFields
                fields={[
                  'overview',
                  'directions',
                  'cookingTips',
                  'cookingMethods',
                  'courses',
                  'styles',
                  'dishTypes',
                  'keyIngredients',
                  'image',
                ]}
              />
              <div className="row mt-10 mb-6">
                <div className="col-12">
                  <span className="d-block mb-2">
                    <strong>Metadata</strong>
                  </span>
                  <AutoFields
                    fields={['metaH1', 'metaTitle', 'recipeUrl', 'metaData']}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <AsideMenu />
      </div>
    </AutoForm>
  );
};

export default RecipeLegacyDetail;
