interface ServingTableItemProps {
  title: string;
  value: string;
}

const ServingTableItem = ({ title, value }: ServingTableItemProps) => {
  return (
    <div className="form-group">
      <label>{title}</label>
      <div className="font-size-base">
        <span className="label label-light label-inline mr-2">{value} </span>
        <small>gr</small>
      </div>
    </div>
  );
};

export default ServingTableItem;
