import get from 'lodash/get';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';

import { IFood } from 'shared/src/types';

const FoodsItem = ({ data }: { data: IFood }) => {
  const phase = get(data, 'phase.name', '');
  const category = get(data, 'category.name', '');

  const isAtkinsAcceptable: boolean = data.isAtkinsAcceptable;

  return (
    <tr>
      <td className="pl-0" style={{ width: 40 }}>
        {isAtkinsAcceptable && (
          <img className="max-h-25px" alt="Logo" src="/svg/logos/atkins.svg" />
        )}
      </td>
      <td className="pl-0">
        <span className="text-dark-75 d-block">{data.name}</span>
      </td>
      <td className="text-center">
        <span className="d-block">{phase}</span>
      </td>
      <td className="text-center">
        <span>{category}</span>
      </td>
      <td className="text-center">
        <span>{(data.netCarbs ?? 0).toFixed(2)} gr</span>
      </td>
      <td className="text-center">
        <Link
          to={`/foods/${data.id}/edit`}
          className="btn btn-icon btn-hover-light btn-sm mx-1">
          <span className="svg-icon svg-icon-md">
            <SVG src="/svg/icons/Communication/Write.svg"></SVG>
          </span>
        </Link>
      </td>
    </tr>
  );
};

export default FoodsItem;
