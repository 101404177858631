import { useMemo, useState, useCallback } from 'react';

import pick from 'lodash/pick';
import { IFoodForm } from 'types';
import { cloneDeep } from 'lodash';
import { DeepPartial } from 'uniforms';
import Modal from 'react-bootstrap/Modal';
import { OptionTypeBase } from 'react-select';
import { Button, Form } from 'react-bootstrap';
import Creatable from 'react-select/creatable';
import configureMeasurements, {
  mass,
  volume,
  MassUnits,
  MassSystems,
  VolumeUnits,
  VolumeSystems,
} from 'convert-units';

import {
  TAny,
  IServingConversion,
  IFoodNutritionalValues,
} from 'shared/src/types';
import NumInput from 'shared/src/components/web/NumInput';

interface IfoodServingConversionsAddProps {
  show: boolean;
  model: DeepPartial<IFoodForm>;
  onClose: (newConvertion?: IServingConversion) => void;
}

type Measures = 'mass' | 'volume';
type Units = MassUnits | VolumeUnits;
type Systems = MassSystems | VolumeSystems;
const convert = configureMeasurements<Measures, Systems, Units>({
  mass,
  volume,
});

const nvalues = [
  'iron',
  'fiber',
  'sodium',
  'protein',
  'calcium',
  'glycerin',
  'vitaminC',
  'totalFat',
  'vitaminA',
  'calories',
  'netCarbs',
  'potassium',
  'glycerides',
  'totalCarbs',
  'totalSugars',
  'cholesterol',
  'saturatedFat',
  'sugarAlcohols',
  'monounsaturatedFat',
  'polyunsaturatedFat',
];

const massConversions = [
  {
    from: ['g', 'gram', 'grams', 'gram(s)'],
    to: 'g',
  },
  {
    from: ['lb'],
    to: 'lb',
  },
  {
    from: ['kg'],
    to: 'kg',
  },
  {
    from: ['oz'],
    to: 'oz',
  },
];

const volumeConversions = [
  {
    from: ['tsp'],
    to: 'tsp',
  },
  {
    from: ['Tbs', 'tbsp'],
    to: 'Tbs',
  },
  {
    from: ['cup', 'cups'],
    to: 'cup',
  },
  {
    from: ['floz', 'fl-oz'],
    to: 'fl-oz',
  },
];

const getValidConversion = (unit: string) => {
  let results: string[] = [];
  massConversions.forEach(({ from, to }) => {
    if (from.indexOf(unit) !== -1) {
      results = [to, 'mass'];
    }
  });

  volumeConversions.forEach(({ from, to }) => {
    if (from.indexOf(unit) !== -1) {
      results = [to, 'volume'];
    }
  });

  return results;
};

const FoodServingConversionsSmart = ({
  model,
  show,
  onClose,
}: IfoodServingConversionsAddProps) => {
  const [servingSize, setServingSize] = useState(1);
  const [equivalence, setEquivalence] = useState(1);
  const [selectValue, setSelectValue] = useState<OptionTypeBase>();
  const [selectedServingUnit, setSelectedServingUnit] = useState<string>();

  const [options, selectOptions, convertionValue, type] = useMemo(() => {
    const [value, type] = getValidConversion(model.servingUnit as string);

    const options =
      type === 'mass'
        ? ['g', 'kg', 'oz', 'lb']
        : ['ml', 'tsp', 'tbsp', 'cup', 'fl-oz'];

    let selectOptions: { label: string; value: string }[] = [];

    if (type !== 'mass') {
      selectOptions = [
        ...selectOptions,
        ...[
          { label: 'g', value: 'g' },
          { label: 'kg', value: 'kg' },
          { label: 'oz', value: 'oz' },
          { label: 'lb', value: 'lb' },
        ],
      ];
    }

    if (type !== 'volume') {
      selectOptions = [
        ...selectOptions,
        ...[
          { label: 'ml', value: 'ml' },
          { label: 'tsp', value: 'tsp' },
          { label: 'tbsp', value: 'tbsp' },
          { label: 'cup', value: 'cup' },
          { label: 'fl-oz', value: 'fl-oz' },
        ],
      ];
    }

    setSelectedServingUnit(options[0]);
    return [options, selectOptions, value, type];
  }, [model.servingUnit]);

  const getModelNutrients = useCallback((model) => {
    const newModel = cloneDeep(model);
    const nutrientValues = pick(newModel, nvalues) as Omit<
      IFoodNutritionalValues,
      'servingUnit' | 'servingSize'
    >;

    return { newModel, nutrientValues };
  }, []);

  const handleEquivalence = () => {
    if (!selectValue) {
      return;
    }
    const { newModel, nutrientValues } = getModelNutrients(model);

    Object.keys(nutrientValues).forEach((key: string) => {
      const value = nutrientValues[key as keyof typeof nutrientValues] ?? 0;
      const baseServingValue = (1 * value) / newModel.servingSize;
      const newValue = (servingSize * baseServingValue) / equivalence;
      nutrientValues[key as keyof typeof nutrientValues] = newValue;
    });

    handleClose({
      servingSize,
      servingUnit: selectValue.label,
      ...nutrientValues,
      isBase: false,
    });
  };

  const handleSubmit = () => {
    const { newModel, nutrientValues } = getModelNutrients(model);

    const convertion = convert(newModel.servingSize)
      .from(convertionValue as Units)
      .to(getValidConversion(selectedServingUnit!)[0] as Units);

    Object.keys(nutrientValues).forEach((key: string) => {
      const value = nutrientValues[key as keyof typeof nutrientValues] ?? 0;

      nutrientValues[key as keyof typeof nutrientValues] =
        (servingSize * value) / convertion;
    });

    handleClose({
      servingSize,
      servingUnit: selectedServingUnit ?? '',
      ...nutrientValues,
      isBase: false,
    });
  };

  const handleChange = (newValue: OptionTypeBase | null) => {
    newValue && setSelectValue(newValue);
  };

  const handleClose = (newConvertion?: IServingConversion) => {
    setServingSize(1);
    setEquivalence(1);
    setSelectValue(undefined);
    setSelectedServingUnit(undefined);

    onClose(newConvertion);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Smart Conversion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-12">
              <Form>
                {type && (
                  <>
                    <Form.Group>
                      <Form.Label>Serving Size</Form.Label>
                      <NumInput
                        value={servingSize}
                        onChange={(value) => setServingSize(value ?? 0)}
                      />
                    </Form.Group>

                    <hr className="my-8" />

                    <h5>Lineal conversion</h5>

                    <Form.Group className="mt-4">
                      <Form.Label>Serving Unit</Form.Label>
                      <Form.Select
                        className="form-control"
                        onChange={(e) =>
                          setSelectedServingUnit((e.target as TAny).value)
                        }>
                        {options.map((name) => (
                          <option key={name} value={name}>
                            {name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>

                    <Button className="mt-4 w-100" onClick={handleSubmit}>
                      Add conversion
                    </Button>

                    <hr className="mt-8" />
                    <span className="d-block text-center">
                      <strong>OR</strong>
                    </span>
                    <hr className="mb-8" />
                  </>
                )}

                <h5>Equivalence conversion</h5>

                <Form.Group className="mt-4">
                  <Form.Label>Serving Unit</Form.Label>
                  <Creatable
                    value={selectValue}
                    options={selectOptions}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mt-4">
                  <Form.Label>
                    Equivalence ratio (1 {model.servingUnit} = X{' '}
                    {selectValue && selectValue.label})
                  </Form.Label>
                  <NumInput
                    value={equivalence}
                    onChange={(value) => setEquivalence(value ?? 0)}
                  />
                </Form.Group>

                <Button
                  className="mt-4 w-100"
                  onClick={handleEquivalence}
                  disabled={!selectValue}>
                  Add equivalence
                </Button>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FoodServingConversionsSmart;
