import { gql } from '@apollo/client';

import { IObject } from 'shared/src/types';
import { USER_SCHEMA } from 'shared/src/graphql/user';

export const GET_USERS_QUERY = gql`
  query getUsers(
    $skip: Int
    $first: Int
    $after: String
    $order: [UserOrder!]
    $where: UserWhereInput
  ) {
    users(
      skip: $skip
      first: $first
      after: $after
      order: $order
      where: $where
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      count
      edges {
        node {
          ${USER_SCHEMA}
        }
      }
    }
  }
`;

export const FORCE_PASSWORD_MUTATION = gql`
  mutation masterResetPassword($username: String!, $password: String!) {
    masterResetPassword(username: $username, password: $password)
  }
`;

export const customWhereGenerator = (vars: IObject) => {
  const { q } = vars;
  const toRet: IObject = {};

  if (q) {
    toRet['OR'] = [
      { fullName: { text: { search: { term: `"${q}"` } } } },
      { username: { equalTo: q } },
      { email: { equalTo: q } },
    ];
  }

  return { where: toRet };
};
