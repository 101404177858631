import Router from 'routers/Router';
import { ToastContainer } from 'react-toastify';
import ApolloProvider from 'shared/src/components/AuthorizedApolloProvider';

import 'theme/index.scss';

const App = () => (
  <ApolloProvider>
    <Router />
    <ToastContainer />
  </ApolloProvider>
);

export default App;
