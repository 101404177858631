import { useState } from 'react';

import { Button, Modal } from 'react-bootstrap';

interface IConfirmationModalProps {
  id: string;
  type: string;
  show: boolean;
  onHide: () => void;
  onComplete: () => void;
}

interface IDeleteConfirmationProps {
  id?: string;
  type?: string;
  className?: string;
  onComplete: () => void;
}

const ConfirmationModal = ({
  show,
  onHide,
  onComplete,
}: IConfirmationModalProps) => {
  const handleHide = () => {
    onHide();
  };

  return (
    <Modal show={show} onHide={handleHide}>
      <Modal.Header>Delete confirmation</Modal.Header>
      <Modal.Body>
        <p>
          Deleting this food is irreversible. Click Delete below to confirm you
          want to permanently delete it.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="control" onClick={handleHide}>
          Cancel
        </Button>
        <Button variant="danger" onClick={() => onComplete()}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const DeleteConfirmation = ({
  id = '',
  type = 'food',
  className = '',
  onComplete,
}: IDeleteConfirmationProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleHide = () => {
    setIsModalVisible(false);
  };

  const handleRemove = () => {
    setIsModalVisible(true);
  };

  return (
    <>
      <ConfirmationModal
        id={id}
        type={type}
        onHide={handleHide}
        show={isModalVisible}
        onComplete={onComplete}
      />

      <Button variant={`danger w-100 ${className}`} onClick={handleRemove}>
        Delete
      </Button>
    </>
  );
};

export default DeleteConfirmation;
