import { connectField, HTMLFieldProps } from 'uniforms';

import BaseComponent from 'components/conversions/FoodServingConversions';

import { IServingConversion } from 'shared/src/types';

const foodServingConversions = ({
  value,
  error,
  required,
  onChange,
}: HTMLFieldProps<IServingConversion[], HTMLInputElement>) => {
  const handleConversionAdded = (newConvertion: IServingConversion) => {
    const newValue = [...(value ?? [])];
    newValue.push(newConvertion);

    onChange(newValue);
  };

  const handleConversionRemoved = (index: number) => {
    const newValue = [...(value ?? [])];
    newValue.splice(index, 1);

    onChange(newValue);
  };

  const handleConversionUpdated = (
    conversionUpdated: IServingConversion,
    index: number
  ) => {
    const newValue = [...(value ?? [])];
    newValue[index] = conversionUpdated;

    onChange(newValue);
  };

  return (
    <div
      className={`form-group ${required ? 'required' : ''} ${
        error ? 'is-invalid' : ''
      }`}>
      <BaseComponent
        data={value}
        onConversionAdded={handleConversionAdded}
        onConversionRemoved={handleConversionRemoved}
        onConversionUpdated={handleConversionUpdated}
      />
      {error && <span className="form-text text-danger">{error.message}</span>}
    </div>
  );
};

export default connectField(foodServingConversions);
