import Modal from 'react-bootstrap/Modal';

interface ILoadingIndicatorProps {
  inline?: boolean;
  visible: boolean;
  animation?: boolean;
}

const LoadingIndicator = ({
  inline,
  visible,
  animation,
}: ILoadingIndicatorProps) => {
  if (!visible) {
    return null;
  }

  const spinnerElm = (
    <div className="spinner-border text-primary" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  );

  if (inline) {
    return spinnerElm;
  }

  return (
    <Modal
      centered
      show={visible}
      animation={animation}
      className="loading-indicator">
      <Modal.Body>{spinnerElm}</Modal.Body>
    </Modal>
  );
};

export default LoadingIndicator;
