import Button from 'react-bootstrap/Button';

import ITEMS_PER_PAGE_OPTIONS from 'constants/itemsPerPageOptions';
import { useMemo } from 'react';

export interface IPaginationProps {
  count: number;
  pageCount: number;
  disabled?: boolean;
  currentPage: number;
  onChangePagination: (text: string) => void;
  onChangeItemsPerPage: (value: number) => void;
}

const Pagination = ({
  count,
  pageCount,
  currentPage,
  disabled = false,
  onChangePagination,
  onChangeItemsPerPage,
}: IPaginationProps) => {
  const handlePaginationClick = (text: string) => () => {
    onChangePagination(text);
  };

  const handleChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChangeItemsPerPage(+event.target.value);
  };

  const pages = useMemo(() => {
    const arr: number[] = [];

    for (let index = currentPage - 2; index < currentPage + 3; index++) {
      arr.push(index);
    }

    return arr.filter((index) => index > 0 && index < pageCount);
  }, [currentPage, pageCount]);

  return (
    <div className="d-flex justify-content-between align-items-center flex-wrap">
      <div className="d-flex flex-wrap py-2 mr-3 ">
        <Button
          onClick={handlePaginationClick('«First')}
          className="btn btn-icon btn-sm btn-light btn-hover-primary mr-2 my-1"
          disabled={currentPage <= 1 || disabled}>
          <span aria-hidden="true">«</span>
          <span className="sr-only">First</span>
        </Button>
        <Button
          onClick={handlePaginationClick('‹Previous')}
          className="btn btn-icon btn-sm btn-light btn-hover-primary mr-2 my-1"
          disabled={currentPage <= 1 || disabled}>
          <span aria-hidden="true">‹</span>
          <span className="sr-only">Previous</span>
        </Button>

        {pages.map((e) => (
          <Button
            key={`paginator-page-${e}`}
            onClick={handlePaginationClick(e.toString())}
            active={currentPage === e}
            disabled={disabled}
            className={`btn btn-icon btn-sm border-0 btn-light btn-hover-primary mr-2 my-1`}>
            {e}
          </Button>
        ))}

        <Button
          onClick={handlePaginationClick('›Next')}
          className="btn btn-icon btn-sm btn-light btn-hover-primary mr-2 my-1"
          disabled={currentPage >= pageCount || disabled}>
          <span aria-hidden="true">›</span>
          <span className="sr-only">Next</span>
        </Button>
        <Button
          onClick={handlePaginationClick('»Last')}
          className="btn btn-icon btn-sm btn-light btn-hover-primary mr-2 my-1"
          disabled={currentPage >= pageCount || disabled}>
          <span aria-hidden="true">»</span>
          <span className="sr-only">Last</span>
        </Button>
      </div>

      <div className="d-flex align-items-center py-3" data-children-count="1">
        <select
          onChange={handleChangeSelect}
          className="form-control form-control-sm font-weight-bold mr-4 border-0 bg-light false"
          style={{ width: 55 }}>
          {ITEMS_PER_PAGE_OPTIONS.map((value: number) => (
            <option key={`paginator-show-${value}`} className="btn">
              {value}
            </option>
          ))}
        </select>
        <span className="react-bootstrap-table-pagination-total">
          &nbsp;Showing {currentPage} -&nbsp;{pageCount} of&nbsp;{count}
        </span>
      </div>
    </div>
  );
};

export default Pagination;
