import { useState } from 'react';
import SVG from 'react-inlinesvg';
import format from 'date-fns/format';

import UsersDetail from 'components/users/UsersDetail';

import { IUser } from 'shared/src/types';

const UsersItem = ({ data }: { data: IUser }) => {
  const formatedDate = format(new Date(data.dob), 'MM/dd/yyyy');
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <tr>
        <td className="pl-0">
          <span className="text-dark-75 d-block">{data.fullName}</span>
        </td>
        <td>
          <span className="d-block">{data.username}</span>
        </td>
        <td>
          <a href="#" className="text-primary mb-1">
            {data.email}
          </a>
        </td>
        <td className="text-center">
          <span>{formatedDate}</span>
        </td>
        <td className="text-center">
          <a
            target="_blank"
            rel="noreferrer"
            href={`mailto:${data.email}`}
            className="btn btn-icon btn-hover-light btn-sm">
            <span className="svg-icon svg-icon-md">
              <SVG src="/svg/icons/Communication/Mail-opened.svg"></SVG>
            </span>
          </a>
          {/*<a href="#" className="btn btn-icon btn-hover-light btn-sm mx-1">
            <span className="svg-icon svg-icon-md">
              <SVG src="/svg/icons/Communication/Write.svg"></SVG>
            </span>
          </a>*/}
          <a
            className="btn btn-icon btn-hover-light btn-sm"
            onClick={handleShow}>
            <span className="svg-icon svg-icon-md">
              <SVG src="/svg/icons/General/User.svg"></SVG>
            </span>
          </a>
        </td>
      </tr>
      <UsersDetail show={show} handleClose={handleClose} data={data} />
    </>
  );
};

export default UsersItem;
