export default [
  {
    title: 'Calories',
    subtitle: 'Kcal',
    value: 'calories',
  },
  {
    title: 'Net Carbs',
    subtitle: 'gr',
    value: 'netCarbs',
  },
  {
    title: 'Protein',
    subtitle: 'gr',
    value: 'protein',
  },
  {
    title: 'Sugar',
    subtitle: 'gr',
    value: 'sugar',
  },
  {
    title: 'Fiber',
    subtitle: 'gr',
    value: 'fiber',
  },
  {
    title: 'Prep Time',
    subtitle: 'min',
    value: 'prepTime',
  },
  {
    title: 'Cook Time',
    subtitle: 'min',
    value: 'cookTime',
  },
];
