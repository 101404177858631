import React, { useState } from 'react';
import { isNaN } from 'lodash';
interface MyNetDiaryFood {
  descForUi: string;
  beanId: number;
  isGramless?: boolean;
  sampleServing: { calories: number; gmWgt?: number; msreDesc: string };
}
const useMyNetDiary = () => {
  const searchByTerm = async (search: string) => {
    const MNDFoods: any[] = [];
    try {
      const url =
        process.env.REACT_APP_MYNETDIARY_ENDPOINT + '?search=' + search;
      if (url) {
        await fetch(url)
          .then((response) => response.json())
          .then((data) => {
            if (data?.entries) {
              data.entries.forEach(function (item: MyNetDiaryFood) {
                const isUpc = false;
                const isGramless = item.isGramless ?? false;
                let sampleServing = item.sampleServing.calories;
                if (
                  item.sampleServing.msreDesc !== 'gram' &&
                  !isGramless &&
                  item.sampleServing.gmWgt
                ) {
                  sampleServing =
                    (item.sampleServing.calories / item.sampleServing.gmWgt) *
                    100;
                }
                const newItem = {
                  labelExtra: '',
                  icon: false,
                  label: item.descForUi,
                  extra: sampleServing.toFixed(2),
                  beanId: item.beanId,
                  name: item.descForUi,
                  isGramless: isGramless,
                  isUpc: isUpc,
                };
                MNDFoods.push(newItem);
              });
            } else {
              let isGramless = true;
              if (search.length >= 12 && !isNaN(search)) {
                if (data.sampleServing.gmWgt) {
                  isGramless = false;
                }
              }
              const newItem = {
                labelExtra: '',
                icon: false,
                label: data.foodDesc,
                extra: data.sampleServing.calories.toFixed(2),
                beanId: data.foodId,
                name: data.foodDesc,
                isGramless: isGramless,
                isUpc: true,
              };
              MNDFoods.push(newItem);
            }
          })
          .catch((error) => console.log('error', error));
      }
    } catch (e) {
      console.log(e);
    }
    return MNDFoods;
  };

  const getById = async (beanId: number) => {
    const url =
      process.env.REACT_APP_MYNETDIARY_ENDPOINT + '?food_id=' + beanId;
    const indexes = {
      carbs: 0,
      protein: 0,
      fat: 0,
      fiber: 0,
      sugarAlcohol: 0,
      calories: 0,
      totalCarbs: 0,
    };
    const object = await fetch(url)
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => console.log('error', error));
    object.nutrients.forEach(function (item: any, index: number) {
      if (item.nutr === 205) {
        indexes.carbs = item.val;
      }
      if (item.nutr === 208) {
        indexes.calories = item.val;
      }
      if (item.nutr === 203) {
        indexes.protein = item.val;
      }
      if (item.nutr === 204) {
        indexes.fat = item.val;
      }
      if (item.nutr === 700) {
        indexes.sugarAlcohol = item.val;
      }
      if (item.nutr === 291) {
        indexes.fiber = item.val;
      }
    });
    indexes.totalCarbs = indexes.carbs - indexes.sugarAlcohol - indexes.fiber;
    object.netCarbs = indexes.totalCarbs;
    object.calories = indexes.calories ?? 0;
    object.protein = indexes.protein ?? 0;
    object.fiber = indexes.fiber ?? 0;
    object.fat = indexes.fat ?? 0;
    return object;
  };

  return {
    searchByTerm,
    getById,
  };
};

export default useMyNetDiary;
