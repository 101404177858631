import { useState } from 'react';

import { isEmpty } from 'lodash';
import Table from 'components/Table';
import Pagination from 'components/Pagination';
import Placeholder from 'components/Placeholder';
import useSearchableTable from 'hooks/useSearchableTable';

import { GET_USERS_QUERY, customWhereGenerator } from 'graphql/users';

import { IUser, TAny, TUsersQuery } from 'shared/src/types';

import { USERS_THEAD } from 'constants/tableHeaders';

const Users = () => {
  const setQ = useState('')[1];
  const [skipFirst, setSkipFirst] = useState(true);
  const {
    data,
    pageInfo,
    onSearch,
    isLoading,
    pagination,
    onChangePagination,
    onChangeItemsPerPage,
  } = useSearchableTable<IUser, TUsersQuery>({
    skipFirst,
    customWhereGenerator,
    orderBy: ['fullName_DESC'],
    queryNode: GET_USERS_QUERY,
  });

  const handleOnSearch = (event: React.FormEvent<HTMLFormElement>) => {
    setSkipFirst(false);
    const formData = new FormData((event as TAny).target);
    const { q } = Object.fromEntries(formData.entries());
    setQ(q as string);
    if (isEmpty(q)) {
      return;
    }
    onSearch(event);
  };

  return (
    <div className="card gutter-b card-custom px-3">
      <div className="card-header border-0 pt-7 pb-0">
        <form onSubmit={handleOnSearch}>
          <div className="d-flex">
            <div className="mr-1">
              <input
                name="q"
                type="text"
                placeholder="Search..."
                className="form-control min-w-260px custom-input"
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isLoading}>
              {isLoading ? 'Loading' : 'Search'}
            </button>
          </div>
        </form>
      </div>
      <div className="card-body d-flex flex-column pt-6">
        <Table data={data} headers={USERS_THEAD} showIn="users" />

        <Placeholder
          spinner
          title="Loading"
          visible={!data.length && isLoading}
        />

        <Placeholder
          iconPath="Communication/Group"
          title="Search for users by name, email, or username."
          visible={skipFirst && !data.length && !isLoading}
        />

        <Placeholder
          iconPath="Communication/Group"
          title="No matching users found"
          visible={!skipFirst && !data.length && !isLoading}
        />
        <div className="mt-auto">
          <hr className="mt-0 mb-8" />

          <Pagination
            pageCount={pagination.pageCount}
            count={pageInfo.count}
            currentPage={pagination.currentPage}
            onChangePagination={onChangePagination}
            onChangeItemsPerPage={onChangeItemsPerPage}
            disabled={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default Users;
