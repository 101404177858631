import { useState, useEffect } from 'react';

import { TAny } from '../../types';
import { digitsOnly } from '../../utils/String';

export type INumbInputProps = {
  removeDots?: boolean;
  decimalSeparator?: string;
};

interface IBaseNumbInputProps extends INumbInputProps {
  error?: TAny;
  name?: string;
  value?: number;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
  onChange: (value?: number) => void;
}

const NumInput = ({
  name,
  value,
  error,
  onChange,
  disabled,
  removeDots,
  placeholder,
  className = '',
  decimalSeparator = '.',
}: IBaseNumbInputProps) => {
  const [currentValue, setCurrentValue] = useState(String(value ?? ''));

  const handleBlur = () => {
    onChange?.(+currentValue ?? undefined);
  };

  const handleFocus = () => {
    setCurrentValue(String(value || ''));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = digitsOnly(
      event.target.value,
      !!removeDots,
      decimalSeparator
    );

    setCurrentValue(value);
    !isNaN(parseInt(value)) && onChange?.(+value ?? undefined);
  };
  useEffect(() => {
    setCurrentValue(String(value || ''));
  }, [value]);
  return (
    <input
      type="text"
      name={name}
      disabled={disabled}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onChange={handleChange}
      placeholder={placeholder}
      value={currentValue ?? value ?? ''}
      className={`form-control ${error ? 'is-invalid' : ''} ${className}`}
    />
  );
};

export default NumInput;
