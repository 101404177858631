import { lazy, Suspense } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';

import useAuth from 'shared/src/hooks/useAuth';

import Login from 'views/auth/Login';
import Logout from 'views/auth/Logout';
import ForgotPassword from 'views/auth/ForgotPassword';

const AuthLayout = lazy(() => import('layouts/Auth'));

import AuthorizedRouter from './AuthorizedRouter';

const Router = () => {
  const { isLoggedIn, isLoadingLoggedInMutation: isLoading } = useAuth();
  if (isLoading) {
    return <Spinner animation="grow" />;
  }

  if (!isLoggedIn) {
    return (
      <BrowserRouter>
        <Suspense fallback={<Spinner animation="grow" />}>
          <AuthLayout>
            <Switch>
              <Route path="/auth/login" exact={true} component={Login} />
              <Route path="/auth/logout" exact={true} component={Logout} />
              <Route
                path="/auth/forgot-password"
                exact={true}
                component={ForgotPassword}
              />
              <Redirect to="/auth/login" />
            </Switch>
          </AuthLayout>
        </Suspense>
      </BrowserRouter>
    );
  }

  return <AuthorizedRouter />;
};

export default Router;
