import Creatable from 'react-select/creatable';

import useApiConstants from 'shared/src/hooks/useApiConstants';
import { TAny } from 'shared/src/types';

const ServingUnitInput = ({ error, value, onChange, placeholder }: TAny) => {
  const { servingUnits } = useApiConstants();

  const handleChangeUnit = (value: TAny) => {
    const newVal = value ? value.label : '';
    onChange(newVal);
  };

  const handleChangeInputUnit = (value: TAny, { action }: TAny) => {
    if (action === 'set-value') {
      handleChangeUnit({ label: value });
    }
  };

  const options = servingUnits.map(({ name }) => ({
    value: name,
    label: name,
  }));

  return (
    <div style={{ minWidth: 150 }}>
      <Creatable
        isClearable
        error={error}
        options={options}
        placeholder={placeholder}
        onChange={handleChangeUnit}
        onInputChange={handleChangeInputUnit}
        value={{ value: value, label: value }}
      />
    </div>
  );
};

export default ServingUnitInput;
