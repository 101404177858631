import { gql } from '@apollo/client';
import { USER_SCHEMA } from 'shared/src/graphql/user';

export const LOGIN_MUTATION = gql`
  mutation logInAcl($fields: LogInInput!) {
    logInAcl(input: $fields) {
      viewer {
        sessionToken
        user {
          ${USER_SCHEMA}
        } 
      }
    }
  }
`;
