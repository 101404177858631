import { FC } from 'react';

import UsersItem from './users/UsersItem';
import FoodsItem from './foods/FoodsItem';
import RecipesItem from './recipes/RecipesItem';
import RecipesLegacyItem from './recipes/RecipesLegacyItem';

import { TAny, IUser, IFood, IRecipe } from 'shared/src/types';

interface IProps {
  data: IUser[] | IFood[] | IRecipe[];
  headers: string[];
  showIn: string;
}

const Table: FC<IProps> = ({ data, headers, showIn }) => {
  const isTextCentered = (value: string) =>
    showIn === 'users'
      ? value === headers[3] || value === headers[4]
      : value !== headers[1];

  let Item: TAny = UsersItem;

  switch (showIn) {
    case 'foods':
      Item = FoodsItem;
      break;
    case 'recipes':
      Item = RecipesItem;
      break;
    case 'recipesLegacy':
      Item = RecipesLegacyItem;
      break;
  }

  return (
    <div className="table-responsive">
      <table className="table table-head-custom table-vertical-center">
        <thead>
          <tr>
            {headers.map((value: string, index: number) => (
              <th
                key={`table-th-${showIn}-${index}`}
                className={isTextCentered(value) ? 'text-center' : 'text-left'}>
                {value}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((dataItem: TAny) => (
            <Item key={`${showIn}-item-${dataItem.id}`} data={dataItem} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
