export default [
  { name: 'Name', value: 'fullName' },
  { name: 'Birthdate', value: 'dob' },
  { name: 'Location', value: ['city', 'state'] },
  { name: 'Username', value: 'username' },
  { name: 'User ID', value: 'legacyId' },
  { name: 'Email', value: 'email', mailto: true },
  { name: 'Member since', value: 'createdAt', dateFormat: 'MMM, dd yyyy' },
  { name: 'Last Login', value: 'updatedAt', distance: true },
  { name: 'Phase', value: 'program.name' },
];
