/* eslint-disable eqeqeq */
import { gql } from '@apollo/client';

import { IObject } from '../types';
import { FOOD_SCHEMA } from './food';

export const RECIPE_BASE_SCHEMA = `
  id
  name
  fiber
  protein
  netCarbs
  objectId
  totalFat
  calories
  sugar @include(if: $detailed)
  isFood @include(if: $detailed)
  metaH1 @include(if: $detailed)
  featured @include(if: $detailed)
  servings @include(if: $detailed)
  overview @include(if: $detailed)
  metaData @include(if: $detailed)
  metaTitle @include(if: $detailed)
  recipeUrl @include(if: $detailed)
  directions @include(if: $detailed)
  totalCarbs @include(if: $detailed)
  cookingTime @include(if: $detailed)
  cookingTips @include(if: $detailed)
  eshaRecipeId @include(if: $detailed)
  originalStatus @include(if: $detailed)
  legacyCreatedAt @include(if: $detailed)
  legacyUpdatedAt @include(if: $detailed)
  preparationTime @include(if: $detailed)
  nutritionInfoId @include(if: $detailed)
  netCarbsOverride @include(if: $detailed)
  image {
    url
    name
  }
  thumbnail {
    url
    name
  }
  phase @include(if: $detailed) {
    id
    name
  }
  difficulty @include(if: $detailed) {
    id
    name
  }
  status @include(if: $detailed) {
    id
    name
  }
  publishingStatus @include(if: $detailed) {
    id
    name
  }
  foods @include(if: $detailed) {
    edges {
      node {
        id
        fat
        order
        fiber
        protein
        foodName
        netCarbs
        calories
        servingSize
        servingUnit
        mndId
        weights
        isGramless
        food {
          ${FOOD_SCHEMA}
        }
      }
    }
  }
  courses @include(if: $detailed) {
    edges {
      node {
        id
        name
      }
    }
  }
  styles @include(if: $detailed) {
    edges {
      node {
        id
        name
      }
    }
  }
  dishTypes @include(if: $detailed) {
    edges {
      node {
        id
        name
      }
    }
  }
  cookingMethods @include(if: $detailed) {
    edges {
      node {
        id
        name
      }
    }
  }
  keyIngredients @include(if: $detailed) {
    edges {
      node {
        id
        name
      }
    }
  }
`;

export const RECIPE_SCHEMA = `
  ${RECIPE_BASE_SCHEMA}

  recipes @include(if: $recipes) {
    edges {
      node {
        id
        order
        quantity
        includedRecipe {
          ${RECIPE_BASE_SCHEMA}
        }
      }
    }
  }
`;

export const GET_RECIPES_SCHEMA = `
  recipes(
    skip: $skip
    last: $last
    first: $first
    after: $after
    order: $order
    where: $where
    before: $before
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    count
    edges {
      node {
        ${RECIPE_SCHEMA}
      }
    }
  }
`;

const BASE_QUERY = `
  atlasRecipes(
    skip: $skip
    last: $last
    first: $first
    after: $after
    order: $order
    where: $where
    before: $before
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    count
    edges {
      node {
        ${RECIPE_SCHEMA}
      }
    }
  }
`;

export const GET_ATLAS_RECIPES_SCHEMA = `${BASE_QUERY}`;
export const GET_ATLAS_RECIPES_COUNT_SCHEMA = `${BASE_QUERY.replace(
  'atlasRecipes',
  'atlasRecipesCount'
)}`;

export const GET_RECIPES_QUERY = gql`
  query getRecipes(
    $skip: Int
    $last: Int
    $first: Int
    $after: String
    $before: String
    $order: [RecipeOrder!]
    $where: RecipeWhereInput
    $detailed: Boolean = true
    $recipes: Boolean = false
  ) {
    ${GET_RECIPES_SCHEMA}
  }
`;

export const GET_ATLAS_RECIPES_QUERY = gql`
  query getAtlasRecipes(
    $skip: Int
    $last: Int
    $first: Int
    $after: String
    $before: String
    $order: [RecipeOrder!]
    $where: RecipeWhereInput
    $detailed: Boolean = true
    $recipes: Boolean = false
  ) {
    ${GET_ATLAS_RECIPES_SCHEMA}
  }
`;

export const GET_ATLAS_RECIPES_COUNT_QUERY = gql`
  query getAtlasRecipesCount(
    $skip: Int
    $last: Int
    $first: Int
    $after: String
    $before: String
    $order: [RecipeOrder!]
    $where: RecipeWhereInput
    $detailed: Boolean = true
    $recipes: Boolean = false
  ) {
    ${GET_ATLAS_RECIPES_COUNT_SCHEMA}
  }
`;

export const GET_RECIPE_SCHEMA = `
  recipes(
    first: 1,
    where: {
      OR: [
        { id: { equalTo: $id }},
        { legacyId: { equalTo: $legacyId }}
      ]
    }) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    count
    edges {
      node {
        ${RECIPE_SCHEMA}
      }
    }
  }
`;

export const GET_RECIPE_QUERY = gql`
  query getRecipes(
    $id: ID!
    $legacyId: Float
    $detailed: Boolean = true
    $recipes: Boolean = false
  ) {
    ${GET_RECIPE_SCHEMA}
  }
`;

export const CREATE_RECIPE_MUTATION = gql`
  mutation createRecipe($fields: CreateRecipeFieldsInput!, $detailed: Boolean = true, $recipes: Boolean = false) {
    createRecipe(input: { fields: $fields }) {
      recipe {
        ${RECIPE_SCHEMA}
      }
    }
  }
`;

export const UPDATE_RECIPE_MUTATION = gql`
  mutation updateRecipe($id: ID!, $fields: UpdateRecipeFieldsInput!, $detailed: Boolean = true, $recipes: Boolean = false) {
    updateRecipe(input: { id: $id, fields: $fields }) {
      recipe {
        ${RECIPE_SCHEMA}
      }
    }
  }
`;

const haveIn = [
  { s: 'phase', p: 'phases' },
  { s: 'difficulty', p: 'difficulties' },
  { s: 'publishingStatus', p: 'publishingStatuses' },
];

const maxMin = [
  'sugar',
  'fiber',
  'protein',
  'calories',
  'netCarbs',
  'cookingTime',
  'preparationTime',
];

export const customWhereGenerator = (vars: IObject) => {
  const { q } = vars;
  const And: IObject[] = [];
  const toRet: IObject = {};

  if (q) {
    toRet.name = { text: { search: { term: q } } };
  }

  haveIn.forEach(({ s, p }) => {
    if (vars[p] && vars[p].length) {
      toRet[s] = { have: { id: { in: vars[p] } } };
    }
  });

  maxMin.forEach((name) => {
    if (vars[`${name}Max`] != null) {
      And.push({ [name]: { lessThanOrEqualTo: vars[`${name}Max`] } });
    }

    if (vars[`${name}Min`] != null) {
      And.push({ [name]: { greaterThanOrEqualTo: vars[`${name}Min`] } });
    }
  });

  if (And.length) {
    toRet.AND = And;
  }

  return { where: toRet };
};
