/* eslint-disable eqeqeq */
import { gql } from '@apollo/client';

import { IObject } from 'shared/src/types';

export const RECIPE_BASE_SCHEMA = `
  id
  name
  fiber
  image
  protein
  netCarbs
  objectId
  totalFat
  calories
  thumbnail
  sugar @include(if: $detailed)
  phase @include(if: $detailed)
  foods @include(if: $detailed)
  isFood @include(if: $detailed)
  metaH1 @include(if: $detailed)
  status @include(if: $detailed)
  styles @include(if: $detailed)
  courses @include(if: $detailed)
  featured @include(if: $detailed)
  servings @include(if: $detailed)
  overview @include(if: $detailed)
  metaData @include(if: $detailed)
  metaTitle @include(if: $detailed)
  recipeUrl @include(if: $detailed)
  dishTypes @include(if: $detailed)
  directions @include(if: $detailed)
  totalCarbs @include(if: $detailed)
  difficulty @include(if: $detailed)
  cookingTime @include(if: $detailed)
  cookingTips @include(if: $detailed)
  eshaRecipeId @include(if: $detailed)
  originalStatus @include(if: $detailed)
  cookingMethods @include(if: $detailed)
  keyIngredients @include(if: $detailed)
  preparationTime @include(if: $detailed)
  nutritionInfoId @include(if: $detailed)
  netCarbsOverride @include(if: $detailed)
  publishingStatus @include(if: $detailed)
`;

export const RECIPE_SCHEMA = `
  ${RECIPE_BASE_SCHEMA}

  recipes @include(if: $recipes)
`;

export const GET_RECIPES_SCHEMA = `
  atlasRecipesLegacyCount(
    skip: $skip
    last: $last
    first: $first
    after: $after
    order: $order
    where: $where
    before: $before
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    count
    edges {
      node {
        ${RECIPE_SCHEMA}
      }
    }
  }
`;

const BASE_QUERY = `
  atlasRecipesLegacyCount(
    skip: $skip
    last: $last
    first: $first
    after: $after
    order: $order
    where: $where
    before: $before
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    count
    edges {
      node {
        ${RECIPE_SCHEMA}
      }
    }
  }
`;

export const GET_ATLAS_RECIPES_SCHEMA = `${BASE_QUERY}`;

export const GET_RECIPES_QUERY = gql`
  query getRecipes(
    $skip: Int
    $last: Int
    $first: Int
    $after: String
    $before: String
    $order: [RecipeLegacyOrder!]
    $where: RecipeWhereInput
    $detailed: Boolean = true
    $recipes: Boolean = false
  ) {
    ${GET_RECIPES_SCHEMA}
  }
`;

export const GET_ATLAS_RECIPES_QUERY = gql`
  query getAtlasRecipesCount(
    $skip: Int
    $last: Int
    $first: Int
    $after: String
    $before: String
    $order: [RecipeLegacyOrder!]
    $where: RecipeLegacyWhereInput
    $detailed: Boolean = true
    $recipes: Boolean = false
  ) {
    ${GET_ATLAS_RECIPES_SCHEMA}
  }
`;

export const GET_RECIPE_SCHEMA = `
  recipeLegacies(
    first: 1,
    where: {
      OR: [
        { id: { equalTo: $id }},
        { legacyId: { equalTo: $legacyId }}
      ]
    }) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    count
    edges {
      node {
        ${RECIPE_SCHEMA}
      }
    }
  }
`;

export const GET_RECIPE_QUERY = gql`
  query getRecipes(
    $id: ID!
    $legacyId: Float
    $detailed: Boolean = true
    $recipes: Boolean = false
  ) {
    ${GET_RECIPE_SCHEMA}
  }
`;

const haveIn = [
  { s: 'phase', p: 'phases' },
  { s: 'difficulty', p: 'difficulties' },
  { s: 'publishingStatus', p: 'publishingStatuses' },
];

const maxMin = [
  'sugar',
  'fiber',
  'protein',
  'calories',
  'netCarbs',
  'cookingTime',
  'preparationTime',
];

export const customWhereGenerator = (vars: IObject) => {
  const { q } = vars;
  const And: IObject[] = [];
  const toRet: IObject = {};

  if (q) {
    toRet.name = { text: { search: { term: q } } };
  }

  haveIn.forEach(({ s, p }) => {
    if (vars[p] && vars[p].length) {
      toRet[s] = {
        in: {
          key: '_id',
          value: vars[p],
        },
      };
    }
  });

  maxMin.forEach((name) => {
    if (vars[`${name}Max`] != null) {
      And.push({ [name]: { lessThanOrEqualTo: vars[`${name}Max`] } });
    }

    if (vars[`${name}Min`] != null) {
      And.push({ [name]: { greaterThanOrEqualTo: vars[`${name}Min`] } });
    }
  });

  if (And.length) {
    toRet.AND = And;
  }

  return { where: toRet };
};
