import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { AutoForm, AutoField } from 'uniforms-bootstrap4';

import useAuth from 'shared/src/hooks/useAuth';
import useForm from 'shared/src/hooks/useForm';

import { SLogin } from 'shared/src/forms/schemas';
import Submit from 'forms/fields/Submit';

import { LOGIN_MUTATION } from 'graphql/user';
import { DeepPartial } from 'uniforms';

const Login = () => {
  const { login, isLoadingLoginMutation: isWorking } = useAuth(LOGIN_MUTATION);
  const { schema } = useForm(SLogin);

  const handleOnSubmit = async (
    data: DeepPartial<{
      username: string;
      password: string;
    }>
  ) => {
    try {
      await login(
        data as {
          username: string;
          password: string;
        }
      );
    } catch (error) {
      const { message } = error as Error;
      toast.error(message);
    }
  };

  return (
    <div className="card border-0">
      <div className="card-header text-center">
        <img
          className="mb-5"
          alt="Atkins Logo"
          src="/svg/logos/atkins.svg"
          height="108"
        />
        <h2 className="card-title h1 mb-0">Administration Dashboard</h2>
      </div>
      <div className="card-body">
        <h3 className="text-muted mb-4">Sign In</h3>
        <AutoForm showInlineError schema={schema} onSubmit={handleOnSubmit}>
          <AutoField name="username" />
          <Link
            to="/auth/forgot-password"
            className="float-right btn-link text-capitalize">
            Forgot password?
          </Link>
          <AutoField name="password" />
          <Submit working={isWorking} text="Sign in" textWorking="Working" />
        </AutoForm>
      </div>
    </div>
  );
};

export default Login;
