import NumInput from '../shared/src/forms/fields/web/NumInput';

import RichText from './fields/RichText';
import ApiSelect from './fields/ApiSelect';
import ServingUnit from './fields/ServingUnit';
import SelectField from './fields/SelectField';
import MultiSelect from './fields/MultiSelect';
import Ingredients from './fields/Ingredients';
import ServingTable from './fields/ServingTable';
import DropzoneUpload from './fields/DropzoneUpload';
import FoodServingConversions from './fields/FoodServingConversions';

import TOTAL_SERVING_OPTIONS from 'constants/totalServingOptions';

import ERRORS from 'shared/src/constants/errors';

const numSchemeConfig = {
  min: 0,
  default: 0,
  type: 'number',
  uniforms: { component: NumInput },
};

const requiredNutrients = [
  'iron',
  'fiber',
  'sodium',
  'protein',
  'calcium',
  'netCarbs',
  'calories',
  'totalFat',
  'vitaminA',
  'vitaminC',
  'glycerin',
  'potassium',
  'totalCarbs',
  'totalSugars',
  'servingSize',
  'servingUnit',
  'cholesterol',
  'saturatedFat',
  'sugarAlcohols',
  'monounsaturatedFat',
  'polyunsaturatedFat',
];

export const SNutrients = {
  servingUnit: {
    type: 'string',
    uniforms: { component: ServingUnit },
  },
  servingSize: {
    ...numSchemeConfig,
    min: 1,
    default: 1,
  },
  calories: {
    ...numSchemeConfig,
  },
  totalFat: {
    label: 'Total Fat',
    ...numSchemeConfig,
  },
  cholesterol: {
    ...numSchemeConfig,
  },
  sodium: {
    ...numSchemeConfig,
  },
  potassium: {
    ...numSchemeConfig,
  },
  totalCarbs: {
    ...numSchemeConfig,
    label: 'Total Carbohydrate',
  },
  netCarbs: {
    ...numSchemeConfig,
  },
  fiber: {
    ...numSchemeConfig,
  },
  protein: {
    ...numSchemeConfig,
  },
  totalSugars: {
    ...numSchemeConfig,
  },
  calcium: {
    ...numSchemeConfig,
  },
  iron: {
    ...numSchemeConfig,
  },
  vitaminA: {
    ...numSchemeConfig,
  },
  vitaminC: {
    ...numSchemeConfig,
  },
  saturatedFat: {
    ...numSchemeConfig,
  },
  monounsaturatedFat: {
    ...numSchemeConfig,
  },
  polyunsaturatedFat: {
    ...numSchemeConfig,
  },
  sugarAlcohols: {
    ...numSchemeConfig,
  },
  glycerin: {
    ...numSchemeConfig,
  },
};

export const SFoodsNutrients = {
  type: 'object',
  properties: {
    ...SNutrients,
  },
  required: [...requiredNutrients],
};

export const SFoodsCU = {
  type: 'object',
  properties: {
    name: {
      type: 'string',
      label: 'Food Name',
      placeholder: 'Enter food name here',
    },
    upcCode: {
      type: 'string',
    },
    boost: {
      ...numSchemeConfig,
      default: 1,
      label: 'Search boost value (1 default value)',
    },
    servingConversions: {
      type: 'array',
      items: {
        type: 'object',
      },
      label: 'Conversions',
      placeholder: 'Add new Conversion',
      uniforms: { component: FoodServingConversions },
    },
    ...SNutrients,
    phase: {
      required: [],
      type: 'object',
      placeholder: 'Select a Phase',
      uniforms: {
        component: ApiSelect,
      },
    },
    category: {
      required: [],
      type: 'object',
      placeholder: 'Select a Category',
      uniforms: { component: ApiSelect },
    },
    publishingStatus: {
      required: [],
      type: 'object',
      placeholder: 'Select a Status',
      uniforms: { component: ApiSelect },
    },
    isAtkinsAcceptable: {
      type: 'boolean',
      placeholder: 'is Atkins Acceptable?',
    },
  },
  required: ['name', ...requiredNutrients],
};

export const SRecipesCU = {
  type: 'object',
  properties: {
    name: {
      type: 'string',
      label: 'Recipe Name',
      placeholder: 'Enter recipe name here',
    },
    featured: {
      type: 'boolean',
    },
    foods: {
      type: 'array',
      label: 'Ingredients',
      items: {
        type: 'object',
      },
      placeholder: 'Add new Ingredient',
      uniforms: { component: Ingredients },
    },
    recipes: {
      type: 'array',
      label: 'Recipes',
      items: {
        type: 'object',
      },
      placeholder: 'Add new Recipe',
      uniforms: { component: Ingredients, type: 'recipes' },
    },
    phase: {
      required: [],
      type: 'object',
      placeholder: 'Select a Phase',
      uniforms: {
        component: ApiSelect,
      },
    },
    difficulty: {
      required: [],
      type: 'object',
      placeholder: 'Select a Difficulty',
      uniforms: { component: ApiSelect },
    },
    preparationTime: {
      label: 'Prep Time',
      ...numSchemeConfig,
    },
    cookingTime: {
      label: 'Cooking Time',
      ...numSchemeConfig,
    },
    overview: {
      type: 'string',
      label: 'Overview',
      placeholder: 'Type Coverview for this recipe',
      uniforms: {
        component: RichText,
      },
    },
    directions: {
      type: 'string',
      label: 'Cooking Instructions',
      placeholder: 'Type Cooking Instructions for this recipe',
      uniforms: {
        component: RichText,
      },
    },
    cookingTips: {
      type: 'string',
      label: 'Cooking Tipes',
      placeholder: 'Type Cooking Tipes for this recipe',
      uniforms: {
        component: RichText,
      },
    },
    cookingMethods: {
      type: 'array',
      items: {
        type: 'object',
      },
      label: 'Cooking Method',
      uniforms: { component: MultiSelect },
    },
    courses: {
      type: 'array',
      label: 'Course',
      items: {
        type: 'object',
      },
      uniforms: { component: MultiSelect },
    },
    styles: {
      type: 'array',
      items: {
        type: 'object',
      },
      label: 'Recipe Style',
      uniforms: { component: MultiSelect },
    },
    dishTypes: {
      type: 'array',
      items: {
        type: 'object',
      },
      label: 'Dish Type',
      uniforms: { component: MultiSelect },
    },
    keyIngredients: {
      type: 'array',
      items: {
        type: 'object',
      },
      label: 'Key Ingredients',
      uniforms: { component: MultiSelect },
    },
    image: {
      type: 'array',
      label: 'Recipe Image (preferred size: 300x300 px)',
      items: {
        type: 'object',
      },
      uniforms: { component: DropzoneUpload },
    },
    servings: {
      type: 'number',
      label: 'Total Servings',
      options: TOTAL_SERVING_OPTIONS,
      default: TOTAL_SERVING_OPTIONS[0].value,
      uniforms: { component: SelectField },
    },
    servingTable: {
      label: '',
      required: [],
      type: 'object',
      uniforms: { component: ServingTable },
    },
    netCarbsOverride: {
      label: 'Net Carb Override',
      ...numSchemeConfig,
    },
    status: {
      required: [],
      type: 'object',
      placeholder: 'Select a Status',
      uniforms: { component: ApiSelect },
    },
    publishingStatus: {
      required: [],
      type: 'object',
      placeholder: 'Select a Status',
      uniforms: { component: ApiSelect },
    },
    metaH1: {
      type: 'string',
      label: 'H1',
    },
    metaTitle: {
      type: 'string',
      label: 'Title',
    },
    recipeUrl: {
      type: 'string',
      label: 'Slug',
    },
    metaData: {
      type: 'string',
      label: 'Description',
      placeholder: 'Type metadata description',
      uniforms: {
        component: RichText,
      },
    },
  },
  errorMessage: {
    required: {
      name: ERRORS.required,
    },
  },
  required: [
    'name',
    'servings',
    'cookingTime',
    'preparationTime',
    'netCarbsOverride',
  ],
};
