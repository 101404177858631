import { useEffect } from 'react';

import isEqual from 'lodash/isEqual';
import startCase from 'lodash/startCase';

import { IObject } from 'shared/src/types';

import ServingTableItem from './ServingTableItem';

import { IRecipeForm } from 'types';
import { IRecipeFoodServingConversion } from 'components/ingredients/Ingredients';

import useServing, { TValues, TValueKey, ELEMENTS } from 'hooks/useServing';
export interface IRecipe extends Omit<IRecipeForm, 'foods' | 'recipes'> {
  foods: IRecipeFoodServingConversion[];
  recipes: IRecipeFoodServingConversion[];
}

export type IServingTableProps = {
  value: TValues;
  formValues: Partial<IRecipe>;
  onChange?: (values: IObject) => void;
};

const ServingTable = ({ value, formValues, onChange }: IServingTableProps) => {
  const servings = +(formValues.servings ?? 1);
  const foods = formValues.foods ?? [];
  const recipes = formValues.recipes ?? [];
  const ingredients = [...foods, ...recipes];
  const servingsConverted = 1 / servings;

  const totals = useServing(ingredients, servingsConverted);

  useEffect(() => {
    if (onChange && !isEqual(value, totals)) {
      onChange(totals);
    }
  }, [totals]);

  return (
    <div className="inline-field list-group list-group-custom">
      {ELEMENTS.map((item) => {
        const title = startCase(item);
        const value = (totals[item as TValueKey] || 0).toFixed(3);
        return (
          <ServingTableItem
            key={`serving-table-item-${item}`}
            title={title}
            value={value}
          />
        );
      })}
    </div>
  );
};

export default ServingTable;
