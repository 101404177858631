import SVG from 'react-inlinesvg';
import { IServingConversion } from 'shared/src/types';

interface IFoodServingConversionsItemProsp {
  index: number;
  data: IServingConversion;
  onUpdateConversion: (index: number) => void;
  onRemoveConversion: (index: number) => void;
}

const FoodServingConversionsItem = ({
  data: { servingSize, servingUnit },
  index,
  onUpdateConversion,
  onRemoveConversion,
}: IFoodServingConversionsItemProsp) => {
  return (
    <div className="form-group d-flex align-items-center">
      <a
        onClick={() => onUpdateConversion(index)}
        className="pointer flex-fill">
        <label className="m-0 font-weight-bold ">{`${servingSize} ${servingUnit}`}</label>
      </a>
      <a className="pointer ml-auto" onClick={() => onRemoveConversion(index)}>
        <span className="svg-icon svg-icon-lg">
          <SVG src="/svg/icons/Navigation/Close.svg" />
        </span>
      </a>
    </div>
  );
};

export default FoodServingConversionsItem;
