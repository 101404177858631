import isEmpty from 'lodash/isEmpty';
import { ChangeEvent, useEffect } from 'react';
import { connectField, HTMLFieldProps } from 'uniforms';
import useApiConstants from 'shared/src/hooks/useApiConstants';

const ApiSelect = ({
  name,
  label,
  value,
  onChange,
  disabled,
}: HTMLFieldProps<{ id: string; name: string }, HTMLSelectElement>) => {
  const constants = useApiConstants();
  let data = constants.phases;
  switch (name) {
    case 'status':
      data = constants.recipeStatuses;
      break;
    case 'category':
      data = constants.foodCategories;
      break;
    case 'servingUnit':
      data = constants.servingUnits;
      break;
    case 'difficulty':
      data = constants.difficulties;
      break;
    case 'publishingStatus':
      data = constants.publishingStatuses;
      break;
  }

  useEffect(() => {
    if (isEmpty(value)) {
      onChange({
        id: data[0].id,
        name: data[0].name,
      });
    }
  }, [value]);

  const handleChangeSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    if (onChange) {
      onChange({
        id: event.target.value,
        name: event.target.name,
      });
    }
  };

  return (
    <div className="form-group">
      <label htmlFor={`${name}-select`}>{label}</label>
      <select
        id={`${name}-select`}
        value={value ? value.id : ''}
        name={value ? value.name : ''}
        className="form-control"
        disabled={disabled}
        onChange={handleChangeSelect}>
        {data.map(({ id, name }) => (
          <option key={`api-select-${id}`} value={id}>
            {name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default connectField(ApiSelect);
