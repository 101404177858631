import get from 'lodash/get';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';

import { IRecipe } from 'shared/src/types';

const RecipesItem = ({ data }: { data: IRecipe }) => {
  const phase = get(data, 'phase.name', '');
  const difficulty = get(data, 'difficulty.name', '');

  return (
    <tr>
      <td className="pl-0">
        <span className="text-dark-75 d-block">{data.name}</span>
      </td>
      <td className="text-center">
        <span className="d-block">{phase}</span>
      </td>
      <td className="text-center">
        <span>{difficulty}</span>
      </td>
      <td className="text-center">
        <span>{(data.netCarbs ?? 0).toFixed(2)} gr</span>
      </td>
      <td className="text-center">
        <Link
          to={`/recipes/${data.id}/edit`}
          className="btn btn-icon btn-hover-light btn-sm mx-1">
          <span className="svg-icon svg-icon-md">
            <SVG src="/svg/icons/Communication/Write.svg"></SVG>
          </span>
        </Link>
      </td>
    </tr>
  );
};

export default RecipesItem;
