import { lazy, Suspense } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';

import Users from 'views/app/Users';
import Foods from 'views/app/Foods/Foods';
import FoodsCU from 'views/app/Foods/FoodsCU';

import Recipes from 'views/app/Recipes/Recipes';
import RecipesCU from 'views/app/Recipes/RecipesCU';

import RecipesLegacy from 'views/app/RecipesLegacy/RecipesLegacy';
import RecipeLegacyDetail from 'views/app/RecipesLegacy/RecipeLegacyDetail';

const MainLayout = lazy(() => import('layouts/Main'));

import useApiConstants from 'shared/src/hooks/useApiConstants';

const AuthorizedRouter = () => {
  const { loading } = useApiConstants();

  if (loading) {
    return <Spinner animation="grow" />;
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<Spinner animation="grow" />}>
        <MainLayout>
          <Switch>
            <Route path="/users" exact={true} component={Users} />
            <Route path="/foods" exact={true} component={Foods} />
            <Route path="/foods/add" exact={true} component={FoodsCU} />
            <Route path="/foods/:id/edit" exact={true} component={FoodsCU} />

            <Route path="/recipes" exact={true} component={Recipes} />
            <Route path="/recipes/add" exact={true} component={RecipesCU} />
            <Route
              path="/recipes/:id/edit"
              exact={true}
              component={RecipesCU}
            />

            <Route
              path="/recipesLegacy"
              exact={true}
              component={RecipesLegacy}
            />
            <Route
              path="/recipesLegacy/:id"
              exact={true}
              component={RecipeLegacyDetail}
            />
            <Redirect to="/users" />
          </Switch>
        </MainLayout>
      </Suspense>
    </BrowserRouter>
  );
};

export default AuthorizedRouter;
