import { connectField, HTMLFieldProps } from 'uniforms';

import BaseComponent from 'components/ServingUnitInput';

const ServingUnit = ({
  value,
  error,
  label,
  required,
  onChange,
  placeholder,
}: HTMLFieldProps<string, HTMLInputElement>) => {
  return (
    <div
      className={`form-group ${required ? 'required' : ''} ${
        error ? 'is-invalid' : ''
      }`}>
      {label && (
        <label className={`${error ? 'text-danger' : ''}`}>{label}</label>
      )}
      <BaseComponent
        value={value}
        error={error}
        onChange={onChange}
        placeholder={placeholder}
      />
      {error && <span className="form-text text-danger">{error.message}</span>}
    </div>
  );
};

export default connectField(ServingUnit);
