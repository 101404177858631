import { ReactNode, useMemo, useState } from 'react';

import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import { toast } from 'react-toastify';
import { DeepPartial } from 'uniforms';
import cloneDeep from 'lodash/cloneDeep';
import { useMutation } from '@apollo/client';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import { AutoForm, AutoField, AutoFields } from 'uniforms-bootstrap4';

import DeleteConfirmation from 'components/DeleteConfirmation';

import Submit from 'forms/fields/Submit';
import { SFoodsCU } from 'forms/schemas';
import LoadingIndicator from 'components/LoadingIndicator';

import useForm from 'shared/src/hooks/useForm';
import useApolloCrud from 'shared/src/hooks/useApolloCrud';
import { convertToGraphqlModel } from 'shared/src/utils/GraphqlHelper';

import { IFood, IServingConversion } from 'shared/src/types';
import {
  GET_FOOD_QUERY,
  CREATE_FOOD_MUTATION,
  UPDATE_FOOD_MUTATION,
} from 'shared/src/graphql/food';
import { IFoodForm } from 'types';
import { DELETE_FOOD } from 'graphql/food';

type TQuery = {
  foods: { edges: { node: IFood }[] };
};

const FoodsCU = () => {
  const { id } = useParams<{ id: string }>();
  const isEdit = !!id;

  const history = useHistory();
  const [deleteFood] = useMutation(DELETE_FOOD);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const {
    query: { data, loading },
    mutations: {
      create: { call: create, loading: isLoadingCreate },
      update: { call: update, loading: isLoadingUpdate },
    },
  } = useApolloCrud<TQuery, IFood>({
    queryNode: GET_FOOD_QUERY,
    queryOptions: {
      variables: {
        id,
      },
      fetchPolicy: 'network-only',
    },
    cachePointers: {
      internal: true,
    },
    skipFirstQuery: !isEdit,
    createMutationNode: CREATE_FOOD_MUTATION,
    updateMutationNode: UPDATE_FOOD_MUTATION,
  });

  const isLoading = isLoadingCreate || isLoadingUpdate;

  const food: Partial<IFood> = useMemo(() => {
    if (isEmpty(data)) {
      return {};
    }
    return data[0];
  }, [data]);

  const { schema } = useForm(SFoodsCU, food);

  if (!loading && isEmpty(food) && isEdit) {
    return <Redirect to="/foods" />;
  }

  if (loading) {
    return <LoadingIndicator visible={true} />;
  }

  const AsideMenu = () => {
    return (
      <div className="aside-menu">
        <AutoFields fields={['isAtkinsAcceptable', 'phase', 'category']} />
        <hr className="mt-10 mb-6" />
        <AutoField name="publishingStatus" />
        <Submit working={isLoading} />
        {food.objectId && (
          <DeleteConfirmation
            className="mt-2"
            id={food.objectId}
            onComplete={handleDelete}
          />
        )}
      </div>
    );
  };

  const Aside = () => {
    return (
      <div className="col">
        <AsideMenu />
      </div>
    );
  };

  const InlineField = ({ children }: { children?: ReactNode }) => {
    return <div className="inline-field list-group">{children}</div>;
  };

  const SubField = ({ children }: { children?: ReactNode }) => {
    return <div className="inline-field sub-field list-group">{children}</div>;
  };

  const handleOnSubmit = async (formData: DeepPartial<IFoodForm>) => {
    const data = cloneDeep(formData);
    data.servingConversions = ((data.servingConversions ??
      []) as IServingConversion[]).filter(({ isBase }) => !isBase);
    if (
      Array.isArray(data?.servingConversions) &&
      data?.servingConversions?.length
    ) {
      data.servingConversions.unshift({
        ...pick(data, [
          'iron',
          'fiber',
          'sodium',
          'calcium',
          'protein',
          'calories',
          'glycerin',
          'netCarbs',
          'totalFat',
          'vitaminA',
          'vitaminC',
          'potassium',
          'totalCarbs',
          'cholesterol',
          'servingSize',
          'servingUnit',
          'totalSugars',
          'saturatedFat',
          'sugarAlcohols',
          'monounsaturatedFat',
          'polyunsaturatedFat',
        ]),
        isBase: true,
      });
    }

    try {
      const cdata = await convertToGraphqlModel(data, food, [
        'servingConversions',
      ]);

      if (isEdit) {
        await update({ variables: { fields: cdata, id } });
      } else {
        const {
          data: {
            createFood: {
              food: { objectId },
            },
          },
        } = await create({ variables: { fields: cdata } });

        window.location.href = `/foods/${objectId}/edit`;
        return;
      }

      toast.success(`Food ${isEdit ? 'updated' : 'saved'} successfuly`);
    } catch (error) {
      const { message } = error as Error;
      toast.error(message);
    }
  };

  const handleDelete = async () => {
    try {
      setIsLoadingDelete(true);
      await deleteFood({
        variables: {
          id: food.objectId,
        },
      });

      setIsLoadingDelete(false);
      history.push('/foods');
    } catch (error) {
      const { message } = error as Error;
      toast.error(message);
    }
  };

  return (
    <>
      <LoadingIndicator visible={isLoadingDelete} />
      <AutoForm
        placeholder
        showInlineError
        schema={schema}
        onSubmit={handleOnSubmit}>
        <div className="row">
          <div className="col-lg-9">
            <div className="card gutter-b card-custom px-3">
              <div className="card-body">
                <AutoFields fields={['name', 'upcCode', 'boost']} />
                <h5 className="text-muted mt-10">Nutritonial Facts</h5>
                <AutoFields
                  element={InlineField}
                  fields={[
                    'servingSize',
                    'servingUnit',
                    'calories',
                    'totalFat',
                  ]}
                />
                <AutoFields
                  element={SubField}
                  fields={[
                    'saturatedFat',
                    'polyunsaturatedFat',
                    'monounsaturatedFat',
                  ]}
                />
                <AutoFields
                  element={InlineField}
                  fields={['cholesterol', 'sodium', 'potassium', 'totalCarbs']}
                />
                <AutoFields element={SubField} fields={['sugarAlcohols']} />
                <AutoFields
                  element={InlineField}
                  fields={[
                    'netCarbs',
                    'fiber',
                    'protein',
                    'totalSugars',
                    'calcium',
                    'iron',
                    'vitaminA',
                    'vitaminC',
                    'glycerin',
                  ]}
                />
                <hr />
                <AutoField name="servingConversions" />
              </div>
            </div>
          </div>
          <Aside />
        </div>
      </AutoForm>
    </>
  );
};

export default FoodsCU;
