import { useState } from 'react';

export interface IPaginationProps {
  itemsPerPage?: number;
}

const usePagination = (props?: IPaginationProps) => {
  const { itemsPerPage = 2 } = props || {};

  const [skip, setSkip] = useState(0);
  const [first, setFirst] = useState(itemsPerPage);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const pageCount = Math.ceil(count / first);
  const setPage = (page: number) => {
    if (page > 0 && page <= pageCount) {
      setSkip(first * (page - 1));
      setCurrentPage(page);
    }
  };

  const setFirstPage = () => {
    setSkip(0);
    setCurrentPage(1);
  };

  const setLastPage = () => {
    setSkip(first * (pageCount - 1));
    setCurrentPage(pageCount);
  };

  return {
    skip,
    first,
    pageCount,
    currentPage,
    itemsPerPage: first,

    setPage,
    setFirst,
    setFirstPage,
    setLastPage,

    setCount,
  };
};

export default usePagination;
