import SVG from 'react-inlinesvg';

interface ISearchIconProps {
  loading: boolean;
}

const WIDTH = 45;
const SearchIcon = ({ loading }: ISearchIconProps) => {
  if (loading) {
    return (
      <div style={{ width: WIDTH }}>
        <div
          className="spinner-border text-light spinner-border-sm"
          role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div style={{ width: WIDTH }}>
      <span className="svg-icon m-0">
        <SVG src={'/svg/icons/General/Search.svg'} />
      </span>
    </div>
  );
};

export default SearchIcon;
