import SVG from 'react-inlinesvg';
import isEmpty from 'lodash/isEmpty';
import { useDropzone } from 'react-dropzone';
import { connectField, HTMLFieldProps } from 'uniforms';
import { useMemo, useState, useCallback, useEffect } from 'react';

type IDropzoneUploadProps = {
  maxFiles?: number;
  maxDragFiles?: number;
};

export interface IFile extends File {
  url?: string;
}

const getSize = (image: IFile) => {
  if (isEmpty(image.size)) {
    return '';
  }

  return `(${image.size / 1000} KB)`;
};

const DropzoneUpload = ({
  value,
  label,
  error,
  onChange,
  disabled,
  maxFiles = 1,
  maxDragFiles = 1,
  accept = 'image/jpeg, image/png',
}: HTMLFieldProps<IFile[], HTMLInputElement, IDropzoneUploadProps>) => {
  const [images, setImages] = useState<IFile[]>([]);

  useEffect(() => {
    setImages(value || []);
  }, [value]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newImages = [
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            url: URL.createObjectURL(file),
          })
        ),
        ...images,
      ];

      newImages.length = maxFiles;
      onChange(newImages);
    },
    [images]
  );

  const removeImageByIndex = useCallback(
    (index: number) => () => {
      const prevImages = [...images];
      const removed = prevImages.splice(index, 1)[0];
      if (removed.url) {
        URL.revokeObjectURL(removed.url);
      }

      onChange(prevImages);
    },
    [images]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept, maxFiles: maxDragFiles, onDrop });

  const dropZoneProps = getRootProps();
  const dropZoneInputProps = getInputProps();

  const containerClasses = useMemo(
    () =>
      `dropzone ${isDragActive ? 'dropzone--active' : ''} ${
        error || isDragReject ? 'dropzone--reject' : ''
      } ${isDragAccept ? 'dropzone--accept' : ''}`,
    [error, isDragActive, isDragReject, isDragAccept]
  );

  const imagePreviews = useMemo(() => {
    if (!images.length) {
      return null;
    }

    return (
      <>
        {images.map((image, index) => {
          return (
            <div key={`preview-${image.name}`}>
              <div className="symbol symbol-140 my-4">
                <img className="image-src" src={image.url} alt={image.name} />
                {!disabled && (
                  <a
                    onClick={removeImageByIndex(index)}
                    className="icon-close svg-icon svg-icon-md">
                    <SVG src="/svg/icons/Code/Error-circle.svg"></SVG>
                  </a>
                )}
              </div>

              <div className="jumbotron py-4">
                {`${image.name} ${getSize(image)}`}
              </div>
            </div>
          );
        })}
      </>
    );
  }, [images]);

  return (
    <div className="dropzone-container">
      {label && (
        <label className={`${error ? 'text-danger' : ''}`}>{label}</label>
      )}

      {!disabled && (
        <div className={containerClasses} {...dropZoneProps}>
          <input {...dropZoneInputProps} />
          <h3 className={`m-0 ${error ? 'text-danger' : ''}`}>
            Drop files here or click to upload.
          </h3>
        </div>
      )}

      {error && <span className="form-text text-danger">{error.message}</span>}
      {imagePreviews}
    </div>
  );
};

export default connectField(DropzoneUpload);
