import { gql } from '@apollo/client';

export const DELETE_FOOD = gql`
  mutation deleteFood($id: ID!) {
    deleteFood(input: { id: $id }) {
      food {
        id
      }
    }
  }
`;
