import { connectField, HTMLFieldProps } from 'uniforms';

import BaseField, {
  IIngredientsProps,
} from 'components/ingredients/Ingredients';

const Ingredients = ({
  name,
  type,
  value,
  label,
  placeholder,
  error,
  onChange,
  disabled,
  poolTime,
}: HTMLFieldProps<
  { id: string; name: string },
  HTMLDivElement,
  IIngredientsProps
>) => {
  return (
    <div className="form-group">
      {label && (
        <label className={`${error ? 'text-danger' : ''}`}>{label}</label>
      )}
      <hr className="my-0" />
      <BaseField
        name={name}
        type={type}
        value={value}
        disabled={disabled}
        poolTime={poolTime}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  );
};

export default connectField(Ingredients);
