import { gql } from '@apollo/client';

export const DELETE_RECIPE = gql`
  mutation deleteRecipe($id: ID!) {
    deleteRecipe(input: { id: $id }) {
      recipe {
        id
      }
    }
  }
`;
